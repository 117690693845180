.c-favorite-toggle {
  position: relative;
  border: none;
  width: 22px;
  height: 22px;
  cursor: pointer;
  background: none;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &::before {
    background-image: url(../../assets/images/icon-favorite.svg);
    z-index: 1;
    transition: all 0.15s $ease-out-expo;
  }

  &::after {
    transform: scale(0.5);
    opacity: 0;
    background-image: url(../../assets/images/icon-favorite-active.svg);
    z-index: 2;
    transition: all 0.25s $ease-out-elastic;
  }

  span {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: $color-brand;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }

  &.is-active {
    &::before {
      transform: scale(0.5);
    }

    &::after {
      transform: scale(1);
      opacity: 1;
    }

    // span {
    //     animation: favoriteToggleGlow 1s $ease-out-expo backwards;
    // }
  }
}

// @keyframes favoriteToggleGlow {
//     0% { opacity: 1; transform: translate(-50%, -50%) scale(0); }
//     100% { opacity: 0; transform: translate(-50%, -50%) scale(1); }
// }
