.c-back-button {
  @include hide-text;

  position: relative;
  width: 32px;
  height: 32px;
  background-image: url(../../assets/images/icon-back.svg);
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  padding: 0;

  // These are a legacy thing, should be removed and parents adjusted
  margin-top: -9px;
  margin-left: -9px;

  &--legible {
    background-color: #000;
    border-radius: 50%;
  }
}
