@mixin success-gradient() {
  @include smooth-gradient(-90deg, #2ecc71, 1);

  background-color: #27ae60;
  border-top-color: #8ef8ba;
}

@mixin failure-gradient() {
  @include smooth-gradient(-90deg, #e74c3c, 1);

  background-color: #c0392b;
  border-top-color: #ff9286;
}

@mixin desktop-center($width: 600px) {
  @include bp("desktop") {
    width: $width;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin center-children() {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
