// @deprecated
.c-confirm-modal {
  @include full-cover;

  position: fixed;
  padding: 45px 25px;

  @media screen and (min-height: 900px) {
    padding: 75px 25px;
  }

  &__background {
    @include full-cover;
  }

  &__wrapper {
    border-radius: $border-radius-large;
    overflow: hidden;
    background-color: $color-black-almost;
    width: 100%;
    min-height: 100%;
    transform: translate3d(0, 25%, 0);
    transition: all 0.75s $ease-out-expo;
    display: flex;
    flex-flow: column;
    max-width: $max-width-modal;
    margin: 0 auto;

    // This fixes a bizarre ios safari transform + border radius bug
    //-webkit-transform: translateZ(0);
    mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

    @media screen and (min-height: 768px) {
      min-height: 500px;
    }

    .is-active & {
      transform: translate3d(0, 0, 0);
    }

    .is-loading & {
      opacity: 0.5;
    }
  }

  &__header {
    position: relative;
    height: 155px;
    flex-grow: 0;

    &::after {
      @include smooth-gradient(0deg, #000, 1);

      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      pointer-events: none;
    }
  }

  &__image {
    @include full-cover;
    @include bgimg;

    box-shadow: 0 0 0 1px #373636 inset;
    cursor: pointer;
    filter: grayscale(1);

    &__logo {
      @include full-cover;
      @include bgimg;
      background-size: 32px;
      background-image: url(../../assets/images/logo-mroom-simple.png);
    }
  }

  &__content {
    background-color: $color-body-highlight;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    box-shadow: 0 0 0 1px #373636 inset;
  }

  &__back {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  &__subtitle {
    color: $color-ui-tertiary;
    margin-bottom: 10px;
  }

  &__title {
    color: $color-ui-primary;
    margin-bottom: 3px;
  }

  &__address {
    font-size: 15px;
  }

  &__info {
    padding: 12px 20px;

    @media screen and (min-height: 768px) {
      padding: 12px 20px 45px 20px;
    }
  }

  &__services {
    position: relative;
  }

  &__confirm {
    color: #fff;
    background: $color-brand;
    position: relative;

    a {
      color: inherit;
      padding: $page-padding;
      display: block;

      .fa {
        position: absolute;
        right: $page-padding;
      }
    }
  }

  &__confirm-notice {
    padding: 15px 0;
    font-size: 12px;
    padding: 12px 20px;
    background-color: rgba(#000, 0.25);
    color: $color-ui-secondary;
  }

  &__notice {
    margin-top: 15px;
  }

  &--no-header-image {
    .c-confirm-modal__header {
      height: 35px;
      position: absolute;
      top: 0;
      left: 0;

      &::after {
        display: none;
      }
    }

    .c-confirm-modal__content {
      padding-top: 35px;
    }
  }
}
