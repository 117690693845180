.c-service-group-container {
  &__header {
    padding: 0 $gutter;
  }

  &__title {
    margin-bottom: 5px;
    color: $color-ui-primary;
    transition: all 0.25s $ease-out-expo;
  }

  &__description {
    font-size: 13px;
    line-height: 20px;
    color: $color-ui-tertiary;
    transition: all 0.25s $ease-out-expo;
  }

  &__services {
    margin: 0;
    padding: 12px 0 0 0;
    list-style-type: none;
  }
}
