.c-simple-confirm {
    @include full-cover;

    background-color: #000;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
        border-radius: $border-radius-large;
        background-color: $color-body-highlight;
        border: solid 1px #373636;
        text-align: center;
        padding: 30px 0;
        transform: translateY(25%);
        transition: all 0.75s $ease-out-expo;

        .is-active & {
            transform: translateY(0);
        }

        .is-loading & {
            opacity: 0.5;
        }
    }

    &__title {
        padding: 10px 40px 0 40px;
        margin-bottom: 20px;
    }

    &__description {
        padding: 0 40px;
        margin-bottom: 10px;
    }

    &__buttons {
        padding-top: 20px;
    }
}
