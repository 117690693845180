.c-mroomshop-link {
  position: relative;
  text-align: center;
  margin-bottom: 20px;

  &__title {
    margin-bottom: 3px;
  }

  &__description {
    color: $color-ui-tertiary;
    font-size: 13px;
    line-height: 20px;
    max-width: 36ch;
    margin-left: auto;
    margin-right: auto;
  }

  &__link {
    display: inline-block;
    padding: 20px;
    font-family: $font-family-heading;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 13px;
    line-height: 15px;
    margin-top: 10px;
  }
}
