.c-text-input {
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-bottom: solid 2px $color-ghost-border;
  background: transparent;
  color: $color-input;
  width: 100%;
  margin-top: 3px;
  padding: 11px 0;
  border-radius: $border-radius-small;

  &:focus {
    color: $color-input-focus;
    border-color: $color-brand;

    & + label {
      color: $color-input-focus;
    }
  }

  &.has-error {
    color: $color-danger;
    border-bottom-color: $color-danger;
  }

  &.is-valid {
    color: $color-input-focus;
    border-bottom-color: $color-success;
  }

  &:-webkit-autofill {
    box-shadow: $color-ghost-border 0 0 0 30px inset !important;
  }
}
