.c-benefit-notice {
  position: relative;
  height: 190px;
  width: 100%;
  border-radius: 10px;
  border: dashed 2px #3d3d3d;
  color: #3d3d3d;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
  line-height: 1.2;
}
