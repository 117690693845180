.c-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: #000;
  border-top: 1px solid #3d3d3d;
  padding: 0 20px;
  text-align: center;
  font-size: 14px;
  height: with-safe-area-bottom($height-navigation);
  line-height: $height-navigation;
  pointer-events: auto;
  transform: translateY(100%);
  transition: all 0.5s $ease-out-expo;
  padding: 0 20px;

  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: $max-width-list - 50px;
    margin: 0 auto;
    height: 100%;
  }

  &__item {
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: $color-ui-tertiary;

    &.is-current {
      color: $color-brand;
    }
  }

  &__text {
    padding: 0 10px;
    line-height: 1;
    letter-spacing: 1px;
    font-weight: $font-weight-strong;
  }

  &__item-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    height: $height-navigation-item;
    background: transparent;
    border: none;
    text-transform: uppercase;
    padding: 0;
  }

  &__icon {
    margin-bottom: 3px;

    svg {
      width: 25px;
      height: 25px;

      path {
        .is-current & {
          fill: $color-brand;
        }
      }
    }
  }

  &__badge {
    position: absolute;
    top: 10px;
    right: 6px;
    background-color: $color-unread-message;
    color: $color-unread-message-text;
    display: inline-block;
    width: 12px;
    height: 12px;
    line-height: 12px;
    font-size: 9px;
    text-align: center;
    border-radius: 50%;
    color: #000;
    font-weight: $font-weight-strong;
  }

  .show-navigation & {
    transform: translateY(0%);
    z-index: 1;
  }

  &.has-under-three-items {
    .c-navigation__inner {
      justify-content: center;
      gap: 2rem;
    }
  }
}
