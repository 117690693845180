.c-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-modal;
  overflow: hidden;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-overlay;
    z-index: 1;
    pointer-events: all;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-flow: column nowrap;

    &,
    &--padding-regular {
      padding: 45px 25px;
    }

    &--padding-tight {
      padding: 30px 15px;
    }
  }

  &__wrapper {
    max-width: $max-width-modal;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;
    pointer-events: all;

    .h-scrollable & {
      height: auto;
    }
  }

  &--valign-middle {
    .c-modal__wrapper {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &--valign-bottom {
    .c-modal__wrapper {
      margin-top: auto;
    }
  }

  &.offset-navigation {
    .c-modal__content {
      padding-bottom: 45px + $height-navigation;
      padding-bottom: calc(45px + #{$height-navigation-with-safe-area});
    }
  }
}
