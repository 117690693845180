.c-timer {
  $pulse-width: 2px;
  $pulse-width-tiny: 1px;

  position: relative;
  color: $color-ui-primary;
  font-size: 32px;
  line-height: 1;
  z-index: 1;

  &__wait-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 0 4px 4px 0 rgba(#000, 0.1995);
    animation: timerIndicatorSpin 10s linear infinite both;

    &,
    .c-timer--wait-0 & {
      background-image: $gradient-queue-0;
    }

    .c-timer--wait-5 & {
      background-image: $gradient-queue-5;
    }

    .c-timer--wait-15 & {
      background-image: $gradient-queue-15;
    }

    .c-timer--wait-30 & {
      background-image: $gradient-queue-30;
    }

    .c-timer--wait-60 & {
      background-image: $gradient-queue-60;
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-body;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transform: translateZ(0);
    margin: 3px;
  }

  &__time {
    position: relative;
    display: inline-block;
    font-weight: 500;
    color: $color-ui-primary;
  }

  &__unit {
    font-size: 10px;
    position: relative;
    color: $color-ui-secondary;
    text-align: center;

    &--small {
      font-size: 8px;
    }
  }

  &__pulse {
    border-radius: 50%;
    position: absolute;
    top: $pulse-width;
    right: $pulse-width;
    bottom: $pulse-width;
    left: $pulse-width;
    border-width: $pulse-width;
    border-style: solid;
    z-index: -1;

    &,
    .c-timer--wait-0 & {
      border-color: $pulse-queue-0;
    }

    .c-timer--wait-5 & {
      border-color: $pulse-queue-5;
    }

    .c-timer--wait-15 & {
      border-color: $pulse-queue-15;
    }

    .c-timer--wait-30 & {
      border-color: $pulse-queue-30;
    }

    .c-timer--wait-60 & {
      border-color: $pulse-queue-60;
    }
  }

  &__status-indicator {
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 35%;
      height: 35%;
    }

    &--success {
      @include success-gradient;
    }

    &--failure {
      @include failure-gradient;
    }
  }

  /**
  * Size definitions
  */

  &--tiny {
    width: $width-timer-tiny;
    height: $width-timer-tiny;
    font-size: 15px;

    .c-timer__wrapper {
      margin: 2px;
    }

    .c-timer__pulse {
      top: $pulse-width-tiny;
      right: $pulse-width-tiny;
      bottom: $pulse-width-tiny;
      left: $pulse-width-tiny;
      border-width: $pulse-width-tiny;
    }
  }

  &--small {
    width: $width-timer-small;
    height: $width-timer-small;
    font-size: 20px;

    .c-timer__wrapper {
      margin: 3px;
    }
  }

  &--regular {
    width: $width-timer-regular;
    height: $width-timer-regular;
    font-size: 32px;

    .c-timer__wrapper {
      margin: 4px;
    }
  }

  &--large {
    width: $width-timer-large;
    height: $width-timer-large;
    font-size: 64px;

    @media screen and (max-height: 600px) {
      font-size: 48px;
      width: $width-timer-large * 0.75;
      height: $width-timer-large * 0.75;
    }

    .c-timer__time {
      transform: translateY(3px);
    }

    .c-timer__unit {
      font-size: 11px;
    }

    .c-timer__pulse {
      display: block;
    }

    .c-timer__wrapper {
      margin: 5px;
      transition: all 1s $ease-out-expo;
    }

    &.c-timer--none {
      .c-timer__time {
        font-size: 65%;
        font-weight: 700;
      }
    }
  }

  &--string {
    .c-timer__time {
      font-size: 75%;
      line-height: 1.1;
      padding-left: 10px;
      padding-right: 10px;
    }

    &.c-timer--font-small {
      .c-timer__time {
        font-size: 50%;
      }
    }
  }

  &--on-panel {
    .c-timer__wrapper {
      background-color: $color-panel-background;
    }
  }
}

@keyframes timerIndicatorSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
