.c-barbershop-timer {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: block;

  &.is-message {
    width: $width-timer-regular;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    flex-shrink: 0;

    > span {
      width: $width-timer-regular;
      height: $width-timer-regular;
      box-shadow: 0 0 0 3px #3d3d3d inset;
      color: $color-ui-tertiary;
      background-color: rgba(#000, 0.9);
      border-radius: 50%;
      font-size: 14px;
      padding: 5px;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &--tiny {
    &.is-message {
      width: $width-timer-tiny;

      > span {
        font-size: 8px;
        width: $width-timer-tiny;
        height: $width-timer-tiny;
      }
    }
  }
}
