.c-tracking-message {
  position: relative;

  &.is-size-small {
    text-align: left;
  }

  &__title {
    .is-size-small & {
      font-weight: 400;
    }
  }

  &__description {
    margin-top: 10px;
    color: $color-ui-secondary;

    .is-size-small & {
      margin-top: 2px;
      font-size: 12px;
      color: $color-ui-tertiary;
      line-height: 14px;
    }
  }
}
