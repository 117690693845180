.c-register-form {
    $country-width: 65px;

    width: 100%;
    //max-width: 320px;
    margin: 0;

    label {
        font-size: 12px;
        color: $color-input;

        a {
            text-decoration: underline;
        }
    }

    &__field {
        padding-top: 20px;
        position: relative;
        margin-bottom: 20px;

        label {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin-bottom: 3px;
        }
    }

    &__instructions {
        font-size: 11px;
        line-height: 1.2;
        margin-top: 6px;
    }

    &__checkbox {
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;

        // input {
        //     vertical-align: middle;
        // }

        input {
            margin-top: 3px;
        }

        label {
            margin-left: 10px;
        }
    }

    &__field + &__checkbox {
        margin-top: 25px;
    }

    &__submit {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;

        // button {
        //     filter: grayscale(1);
        // }
    }

    &__register {
        text-align: center;
        margin-top: 25px;
        font-size: 13px;
        color: $color-text-secondary;
    }

    &__country-code {
        position: absolute;
        top: 23px;
        left: 5px;
        width: 65px;
    }

    input[type="tel"],
    input[type="text"],
    input[type="email"],
    input[type="password"] {
        font-size: 16px;
        font-weight: 500;
        border: solid 1px transparent;
        background: rgba(255, 255, 255, 0.12);
        border-radius: $border-radius-large;
        color: $color-input;
        width: 100%;
        padding: 7px 10px;

        &:focus {
            color: $color-input-focus;

            & + label {
                color: $color-input-focus;
            }
        }

        &.has-error {
            border: solid 1px $color-danger;
        }
    }

    // input[type="tel"] {
    //     padding: 6px 10px;
    // }

    input.c-register-form__phone {
        padding-left: $country-width + 12px;
    }

    &__thank-you {
        h2,
        h3,
        h4 {
            margin: 0 0 1em;
        }
    }

    // dynamic error messages, frontend validation
    &__error-message {
        color: $color-danger;
        font-size: 12px;
        margin-top: 0;
    }

    // if we get an error message from the API
    &__errors {
        color: $color-danger;
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .is-submittable &__submit {
        button {
            filter: grayscale(0);
        }
    }
}
