.c-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2d2d2d;
  animation: mapFadeIn 1s $ease-out-expo both;
  pointer-events: auto;

  // &__embed {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background-image: url(../../assets/images/placeholder-map.jpg);
  //     background-position: 50% 50%;
  //     background-size: auto 667px;
  //     background-repeat: no-repeat;
  // }

  &__toggle {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    bottom: 45px;
    background-image: url(../../assets/images/icon-expand.png);
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.5s $ease-out-expo;
    z-index: 2;

    &--compress {
      background-image: url(../../assets/images/icon-compress.png);
      transform: translateY(-25px);
    }
  }

  &__link {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 3;

    a {
      display: block;
    }
  }

  &__locator {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 3;
  }

  &__close {
    position: fixed;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-image: url(../../assets/images/icon-close-map.png);
    background-size: 20px 20px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 3;
  }

  &__error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 25px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    h2 {
      margin-bottom: 15px;
    }
  }
}

// Remove some of the Google branded stuff
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

@keyframes mapFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
