.c-check-in {
  @include scene;

  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  overflow: hidden;
  height: 100%;
  z-index: 1;
  transition: all 1s $ease-out-expo;

  // Avoid sub-pixel overflow issues when transitioning
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    bottom: -2px;
    z-index: -1;
    background-image: url(../../assets/images/title-background.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__wrapper {
    position: relative;
    background-color: $color-body-highlight;
    border-radius: $border-radius-large;
    border: solid 1px #373636;
    padding: 35px 25px 25px;
    text-align: center;
    margin-bottom: 50px;

    p {
      font-size: 15px;
      color: $color-ui-secondary;
      line-height: 1.2;
      font-weight: 500;
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__receipt {
    margin-top: 20px;
    color: $color-link;
    text-transform: uppercase;
  }

  // &__time {
  //     position: absolute;
  //     top: 20px;
  //     right: 20px;
  // }

  &__time {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    margin-bottom: 25px;
  }

  &__links {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px;

    a {
      font-weight: 700;
      font-size: 14px;
      display: inline-block;
      margin: 0 10px;
      color: $color-ui-primary;

      span {
        display: block;
        font-size: 11px;
        font-weight: 400;
        color: $color-ui-secondary;
        line-height: 1.2;
        margin-top: 5px;
      }
    }
  }

  &__leave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate(0, 100%);
  }
}
