.c-extra-service-group-container {
  position: relative;
  padding: 12px 0 18px;

  &__title {
    font-size: 13px;
    font-family: $font-family-heading;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $color-ui-tertiary;
    padding-top: 8px;
    padding-left: $gutter;
    padding-right: $gutter;
    margin-bottom: 8px;
  }

  &__services {
    box-sizing: border-box;
    overflow: hidden;
    list-style-type: none;
    padding: 0 $gutter;
  }
}
