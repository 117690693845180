.s-home {
  @include scene;

  &__wrapper {
    position: relative;
    width: 100%;
    z-index: 1;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: with-safe-area-bottom(with-navigation(20px));
    text-align: left;
    transition: all 1s $ease-out-expo;

    > * {
      pointer-events: auto;
      max-width: $max-width-list;
      margin: 0 auto;
    }

    .show-tracking-button & {
      padding-bottom: with-tracking-button(
        with-safe-area-bottom(with-navigation(20px))
      );
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }

  &__title {
    text-align: center;
    margin-left: auto;
  }

  &__language {
    display: flex;
    margin-left: auto;

    a {
      padding: 0;
      line-height: 0;
    }

    path {
      stroke: #838383;
    }
  }

  &__list {
    position: relative;
    z-index: 1;

    &--favorites + &--alphabetical {
      margin-top: 12px;
    }
  }

  &__item {
    pointer-events: auto;
    max-width: $max-width-list;
    margin: 0 auto;

    &:first-child {
      .s-home__label {
        margin-top: 0;
      }
    }
  }

  &__search {
    margin-bottom: 20px;
  }

  &__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  &__no-results {
    margin-top: 30px;
    text-align: center;
  }

  &__buttons {
    margin-bottom: 20px;
  }

  &__map {
    @include full-cover;
    z-index: 10;
  }
}
