.s-queue {
  @include scene;

  padding: 0 30px;
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;

  &__wrapper {
    position: relative;
    border-radius: $border-radius-large;
    background-color: $color-black-almost;
    transition: all 0.5s $ease-out-expo;
    max-width: $max-width-modal;
    margin: 0 auto;
    width: 100%;
  }

  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 45px 0 20px 0;

    @media screen and (max-height: 600px) {
      padding: 30px 0 10px 0;
    }
  }

  &__location {
    text-align: left;
    margin-top: 30px;
    min-height: 87px;
    overflow: hidden;

    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media screen and (max-height: 600px) {
      margin-top: 10px;
    }
  }

  &__receipt-toggle {
    margin-top: auto;
    padding-top: 40px;
    padding: 5px 0;
    height: 30px;
    font-size: 14px;
    color: $color-ui-tertiary;
    text-align: right;
    transition: all 0.5s $ease-out-expo;
    max-width: $max-width-modal;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  &__leave {
    padding-bottom: 20px;
    margin-bottom: auto;
    padding: 5px 30px 0;
    text-align: center;
    transition: all 0.5s $ease-out-expo;
  }

  &__message {
    text-align: center;
    padding: 0 30px;
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(#000, 0);
    transition: all 0.5s $ease-out-expo;
    z-index: 2;

    &.is-active {
      background-color: rgba(#000, 1);
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  &__spinner {
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 99;

    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 99;
    opacity: 0;
    transition: all 0.5s $ease-out-expo;
  }

  &__check-in {
    @include full-cover;

    z-index: 1;
    transform: translateX(100%);
    transition: all 0.5s $ease-out-expo;

    &.is-active {
      transform: translateX(0);
    }
  }

  &__check-in-button {
    border-bottom-left-radius: $border-radius-large;
    border-bottom-right-radius: $border-radius-large;
    overflow: hidden;
  }

  &__confirm {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s $ease-out-expo;

    &.is-active {
      opacity: 1;
      visibility: visible;
      transition: all 0.5s $ease-out-expo;
    }
  }

  &__map {
    @include full-cover;

    background-image: url(../../assets/images/map-loading-placeholder-large.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #bababa;
    transform: translateY(100%);
    transition: all 0.5s $ease-out-expo;

    &.is-active {
      transform: translateY(0);
    }
  }

  &__map-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__receipt {
    @include full-cover;

    color: #fff;
    background-color: #000;
    transform: translateY(100%);
    transition: all 0.5s $ease-out-expo;

    &.is-active {
      transform: translateY(0);
    }
  }

  // &__refresh {
  //     position: absolute;
  //     top: 2px;
  //     left: 2px;
  //     z-index: 100;
  // }

  &--loading {
    // .s-queue__wrapper,
    // .s-queue__leave,
    // .s-queue__receipt {
    //     opacity: 0.5;
    // }

    .s-queue__spinner {
      opacity: 1;
    }
  }
}
