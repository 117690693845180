.c-tutorial-carousel {
    position: relative;

    &__slide {
        position: relative;
        width: 100%;
        text-align: center;
        padding-top: $height-display-number + 30px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 30px;
        opacity: 0;
        transition: opacity 0.5s $ease-out-expo;

        @media screen and (max-height: 500px) {
            padding-top: $height-display-number-small + 20px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 20px;
        }

        &.is-selected {
            opacity: 1;
        }
    }

    &__content {
        max-width: 300px;
        margin: 0 auto;
    }

    &__buttons {
        margin-top: 60px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        @media screen and (max-height: 500px) {
            margin-top: 25px;
        }

        button {
            margin-bottom: 25px;

            @media screen and (max-height: 500px) {
                margin-bottom: 12px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}