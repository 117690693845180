.c-barbershops-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2d2d2d;
  pointer-events: auto;
  overflow: hidden;

  &__container {
    @include full-cover;
    z-index: 2;
  }

  &__close {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    z-index: 3;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
      filter: drop-shadow(0 2px 3px rgba(#000, 0.5));
    }
  }
}
