.s-tutorial {
    @include scene;

    display: flex;
    flex-flow: column nowrap;
    padding: 30px 0 20px;

    &__carousel {
        position: relative;
        margin-top: auto;
        margin-bottom: auto;
        transition: all 0.5s $ease-out-expo;
    }

    &__dots {
        transition: all 0.5s $ease-out-expo;
    }

    &__number {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
    }

    &__next {
        position: fixed;
        bottom: 0;
        right: 0;
        height: $height-page-dots;
        height: calc(#{$height-page-dots} + env(safe-area-inset-bottom));
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 25px;
        font-size: 13px;
        color: $color-ui-tertiary;
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }

    &--loading {
        .s-tutorial__carousel,
        .s-tutorial__dots {
            opacity: 0.5;
        }
    }
}
