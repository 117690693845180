.c-slider-group {
  position: relative;

  &__slider {
    margin-left: -25px;
    margin-right: -25px;
  }

  &__title {
    margin-bottom: 25px;
  }

  &__description {
    margin-top: 25px;
    color: $color-ui-tertiary;
    font-size: 15px;
    line-height: 1.333;
  }
}
