.c-inline-notice {
  position: relative;
  border-radius: 5px;
  background-color: rgba(#fff, 0.1);
  padding: 15px 20px;

  p {
    font-size: 15px;
    line-height: 18px;
  }

  &__button {
    margin-top: 12px;
    margin-bottom: 2px;

    a {
      display: inline-flex;
    }
  }

  strong {
    font-weight: 700;
  }

  &--warning {
    border-radius: 15px;
    padding: 15px 20px 20px;
    background-color: #ffeaa7;
    color: #000;
  }

  &.is-dim {
    background-color: rgba(#fff, 0.05);
  }
}
