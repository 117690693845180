// @deprecated
.s-search {
  $height-header: 85px;
  $height-header-l: $height-header * 1.35;
  $height-header-xl: $height-header * 1.5;

  @include scene;

  &__header {
    background-color: #201f1f;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 15px 15px;
    height: $height-header;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    z-index: 2;
    box-shadow: 0 3px 30px 0 rgba(#000, 0.8);

    @media screen and (min-height: 768px) {
      height: $height-header-l;
    }

    @media screen and (min-height: 900px) {
      height: $height-header-xl;
    }
  }

  &__input {
    position: relative;
    width: 100%;
    max-width: $max-width-list;
    margin: 0 auto;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background-image: url(../../assets/images/icon-search.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      pointer-events: none;

      @media screen and (min-height: 768px) {
        width: 24px;
        height: 24px;
        left: 15px;
      }

      @media screen and (min-height: 900px) {
        width: 28px;
        height: 28px;
        left: 20px;
      }
    }

    input {
      width: 100%;
      background-color: rgba(#8e8e93, 0.12);
      color: #fff;
      border: none;
      border-radius: $border-radius-large;
      padding: 8px 35px;
      font-size: 16px;

      @media screen and (min-height: 768px) {
        padding: 12px 35px 12px 45px;
        font-size: 20px;
      }

      @media screen and (min-height: 900px) {
        padding: 18px 35px 18px 65px;
        font-size: 23px;
      }
    }
  }

  &__clear {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 33px;
    height: 33px;
    background-image: url(../../assets/images/icon-clear.svg);
    background-size: 13px 13px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;

    @media screen and (min-height: 768px) {
      right: 5px;
    }

    @media screen and (min-height: 900px) {
      right: 10px;
    }
  }

  &__results {
    margin-top: $height-header;
    padding: 20px 20px ($height-navigation + 20px);
    padding-bottom: calc(#{$height-navigation-with-safe-area} + 20px);
    z-index: 1;

    @media screen and (min-height: 768px) {
      margin-top: $height-header-l;
    }

    @media screen and (min-height: 900px) {
      margin-top: $height-header-xl;
    }
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
