.c-checkbox {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;

  &__input {
    margin-right: 10px;
    margin-top: 1px;
  }

  &__label {
    color: $color-ui-secondary;
    font-size: 13px;
    line-height: 1.3;
  }

  &.is-align-left {
    justify-content: flex-start;
  }

  &.is-size-large {
    .c-checkbox__input {
      margin-top: 3px;
    }

    .c-checkbox__label {
      font-size: 15px;
    }
  }
}
