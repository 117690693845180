.c-icon-title-text {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  &__icon {
    svg {
      width: 40px;
      height: 40px;

      path {
        fill: $color-brand;
      }
    }
  }

  &__content {
    padding-top: 3px;
    padding-left: 25px;
  }

  &__text {
    color: $color-ui-tertiary;
    font-size: 15px;
    line-height: 20px;
    margin-top: 8px;
  }
}
