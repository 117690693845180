/**
 * @license
 * MyFonts Webfont Build ID 4230497, 2021-11-29T12:34:12-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirNextLTPro-Demi by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/avenir-next-pro/pro-demi/
 * Copyright: Copyright &amp;#x00A9; 2004 - 2017 Monotype GmbH. All rights reserved.
 *
 *
 *
 * © 2021 MyFonts Inc
*/

@font-face {
  font-family: "AvenirNextLTPro-Demi";
  src: url('../assets/fonts/AvenirNextLTProDemi/font.woff2') format('woff2'), url('../assets/fonts/AvenirNextLTProDemi/font.woff') format('woff');
  font-display: swap;
}

