.c-service-toggle {
  $info-icon-size: 18px;
  $button-price-offset: 55px;

  position: relative;
  background-color: transparent;
  color: $color-ui-primary;
  cursor: pointer;

  &__button {
    position: relative;
    padding-top: 17px;
    padding-right: $gutter + $button-price-offset;
    padding-bottom: 15px;
    padding-left: $gutter + 35px;
    background: none;
    border: none;
    display: block;
    text-align: left;
    width: 100%;
    user-select: none;

    .is-selected.has-extra-services & {
      border-bottom: solid 1px #3d3d3d;
    }
  }

  &__title {
    margin-bottom: 2px;
  }

  &__description {
    color: $color-ui-tertiary;
    font-size: 13px;
    line-height: 20px;
    font-weight: $font-weight-default;
  }

  &__price {
    font-weight: $font-weight-default;
    font-size: 13px;
    color: $color-ui-secondary;
    position: absolute;
    top: 17px;
    right: $gutter;
  }

  &__icon {
    position: absolute;
    top: 16px;
    left: $gutter;
  }

  &__info {
    position: absolute;
    top: 7px;
    right: $gutter - 10px;
    padding: 10px;

    svg {
      width: $info-icon-size;
      height: $info-icon-size;
    }
  }

  .has-selected & {
    opacity: 0.4;
  }

  &.is-selected {
    background-color: $color-body-highlight;
    opacity: 1;

    & + & {
      box-shadow: 0 1px 0 0 #000 inset;
    }
  }

  &.has-info {
    .c-service-toggle__button {
      padding-right: $gutter + $button-price-offset + $info-icon-size;
    }

    .c-service-toggle__price {
      right: $gutter + $info-icon-size + 8px;
    }
  }

  &.is-size-large {
    .c-service-toggle__title {
      font-size: 20px;
    }

    .c-service-toggle__icon {
      top: 19px;
    }

    .c-service-toggle__price {
      font-size: 15px;
    }
  }
}
