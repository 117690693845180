.c-link {
  font-size: 14px;
  color: $color-link;
  font-weight: 700;
}

button.c-link {
  background: none;
  border: none;
  padding: 0;
}
