.s-messages {
  @include scene;

  &__back {
    border-bottom: 1px solid rgba(#fff, 0.15);
    padding: 35px 20px 15px 20px;
  }

  &__header {
    padding: 25px 30px 20px;
    max-width: $max-width-readability;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 3px;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }

  &__content {
    border-top: solid 1px #282424;
    padding: 30px 30px 20px;
    max-width: $max-width-readability;
    margin: 0 auto;
    transition: opacity 0.5s $ease-out-expo;
  }

  &__badge {
    background-color: $color-unread-message;
    color: $color-unread-message-text;
    display: inline-block;
    margin-left: 10px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    font-size: 16px;
    text-align: center;
    border-radius: 50%;
  }
}
