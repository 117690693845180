.c-menu-item {
  position: relative;
  border-bottom: 1px solid #282424;
  padding: 5px 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
  min-height: 85px;
  transition: all 0.25s $ease-out-expo;

  &__title {
    margin-bottom: 2px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
  }

  &__subtitle {
    font-size: 13px;
    color: $color-ui-tertiary;
  }

  &__switch {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__badge {
    background-color: $color-unread-message;
    color: $color-unread-message-text;
    display: inline-block;
    margin-left: 7px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
  }

  &--link {
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      width: 8px;
      height: 13px;
      background-image: url(../../assets/images/icon-menu-item-arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    .no-touch &:hover {
      background-color: #0f0f0f;
    }
  }

  &--priority {
    color: $color-ui-priority;

    &.c-menu-item--link {
      &::after {
        background-image: url(../../assets/images/icon-menu-item-arrow-priority.svg);
      }
    }
  }
}
