.c-extra-service-display {
  position: relative;
  padding-right: 55px;
  font-size: 13px;

  &__title {
    font-weight: normal;
  }

  &__price {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 1px;
  }

  & + & {
    margin-top: 3px;
  }
}
