.s-profile {
  @include scene;

  &__wrapper {
    position: relative;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: with-safe-area-bottom(with-navigation(0px));
    max-width: $max-width-list;
    margin: 0 auto;

    .show-tracking-button & {
      padding-bottom: with-tracking-button(
        with-safe-area-bottom(with-navigation(30px))
      );
    }
  }

  &__title {
    color: $color-ui-tertiary;
    padding: 40px 30px 0;
  }

  &__name {
    color: $color-ui-primary;
    padding: 5px 30px 15px;
    margin-top: 20px;
  }

  &__header {
    padding: 30px 30px 0px;
  }

  &__membership {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    box-shadow: $box-shadow-default;
  }

  &--member {
    .s-profile__wrapper {
      padding-top: 50px;
    }
  }
}
