.c-slider {
  position: relative;
  overflow: hidden;
  padding-left: 25px;
  padding-right: 25px;

  .keen-slider {
    overflow: visible;
  }
}
