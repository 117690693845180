.c-btn {
  font-family: $font-family-heading;
  font-size: 14px;
  line-height: 1.355;
  font-weight: 600;
  color: #fff;
  background-color: $color-button;
  cursor: pointer;
  padding: 18px 30px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  border: none;
  border-radius: $border-radius-default;
  transition: all 0.25s $ease-out-expo;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.11);
  letter-spacing: 1px;

  &__icon {
    font-size: 0;
    margin-right: 10px;
  }

  &.is-disabled {
    filter: grayscale(1);
  }

  &.has-icon {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    &.has-icon-after {
      flex-flow: row-reverse nowrap;

      .c-btn__icon {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  &--small {
    padding: 7px 15px;
    font-size: 13px;

    @include bp(sm) {
      padding: 10px 17px;
      font-size: 15px;
    }
  }

  &--full {
    width: 100%;
  }

  &--rounded {
    border-radius: $border-radius-default;
  }

  &--dark {
    background-color: #333;
    color: $color-brand;
    text-shadow: none;
  }

  &--success {
    background-color: $color-success;
  }

  &--danger {
    background-color: $color-danger;
  }

  // @deprecated
  &--queue {
    @include smooth-gradient(-90deg, $color-button-secondary, 1);

    display: block;
    height: $height-queue-button;
    width: 100%;
    font-size: 13px;
    padding: 15px 30px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-button;
    border-top: solid 1px #e5b42d;

    @media screen and (min-height: 768px) {
      text-align: center;
      justify-content: center;
    }

    &::after {
      display: inline-block;
      content: "";
      width: 10px;
      height: 15px;
      background-image: url(../../assets/images/icon-arrow-right-shadow.png);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;

      @media screen and (min-height: 768px) {
        margin-left: 15px;
      }
    }
  }

  // @deprecated
  &--queue-disabled {
    cursor: not-allowed;
    display: block;
    height: $height-queue-button;
    width: 100%;
    font-size: 13px;
    padding: 15px 30px;
    background-color: #129245;

    &::after {
      display: none;
    }

    span {
      display: block;
      text-transform: none;
    }
  }

  &--confirm {
    @include smooth-gradient(-90deg, $color-button-secondary, 1);

    display: block;
    height: 55px;
    width: 100%;
    font-size: 13px;
    padding: 13px 20px 15px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-button;
    border-top: solid 1px #e5b42d;
    transition: filter 0.25s $ease-out-expo;
    cursor: pointer;

    &::after {
      display: inline-block;
      content: "";
      width: 10px;
      height: 15px;
      background-image: url(../../assets/images/icon-arrow-right-shadow.png);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .icon {
      &,
      & > svg {
        margin-left: auto;
        margin-right: auto;
        width: 16px;
        height: 16px;
      }
    }

    &.is-loading {
      cursor: not-allowed;

      &::after {
        display: none;
      }
    }

    &.is-completed {
      @include success-gradient;
      cursor: auto;

      &::after {
        display: none;
      }
    }
  }

  &--text {
    background-color: transparent;
    padding: 5px 20px;
    color: $color-link;
    text-shadow: none;
  }

  &--receipt {
    padding: 5px 0;
    height: 30px;
    font-size: 11px;
    color: $color-ui-tertiary;
    background: none;
    border: none;
    text-transform: uppercase;
    text-shadow: none;
    letter-spacing: 1px;
  }

  &--locate {
    font-size: 13px;
    padding: 6px 13px;
    border-radius: $border-radius-small;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include bp(sm) {
      padding: 10px 17px;
      font-size: 15px;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(../../assets/images/icon-locator-small.svg);
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-right: 10px;
    }
  }

  &--locate-priority {
    @include smooth-gradient(-90deg, $color-button-secondary, 1);

    font-size: 13px;
    padding: 8px 20px 8px;
    border-radius: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $color-button;
    box-shadow: 0 -1px 0 0 #e5b42d inset;
    line-height: 1.1;

    @include bp(sm) {
      justify-content: center;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(../../assets/images/icon-locator-small.svg);
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-right: 10px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 10px;
      height: 15px;
      margin-top: 1px;
      background-image: url(../../assets/images/icon-arrow-right-shadow.png);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &--locate-failure {
    font-size: 13px;
    padding: 8px 20px 8px;
    border-radius: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-danger;
    text-shadow: none;
    font-weight: 400;
    color: #fff;
    text-transform: none;
    line-height: 1.1;

    span {
      max-width: 300px;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 15px;
      margin-top: -10px;
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(../../assets/images/icon-blocked.svg);
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-right: 10px;
    }
  }

  &--continue {
    background-color: transparent;
    padding: 5px 15px 5px 20px;
    color: $color-ui-secondary;
    font-size: 13px;
    text-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 13px;
      background-image: url(../../assets/images/icon-continue.svg);
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-left: 8px;
    }
  }

  &--exit {
    font-family: $font-family-default;
    background-color: transparent;
    color: $color-ui-tertiary;
    text-transform: none;
    font-size: 14px;
    text-align: center;
    transition: all 0.5s $ease-out-expo;
    width: 100%;
    font-weight: 400;
    letter-spacing: 0;

    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url(../../assets/images/icon-exit.svg);
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-right: 12px;
    }
  }

  &--critical-cta {
    background: linear-gradient(90deg, #e1ad1c 22.51%, #d39333 85.56%);
    background-color: #e1ad1c;
    width: 100%;

    .c-btn__icon {
      margin-right: 20px;
    }

    &.has-icon-after {
      .c-btn__icon {
        margin-right: 0;
        margin-left: 20px;
      }
    }

    .c-btn__content {
      display: block;

      /**
       * This magic number is just based on the font size.
       * It's there to make sure the button size doesn't jump
       * when it has an icon like a spinner as content.
       *
       * @todo This is not optimal, rethink this.
       */
      min-height: 19px;

      .icon {
        &,
        & > svg {
          margin-left: auto;
          margin-right: auto;
          width: 16px;
          height: 16px;
          display: block;
        }
      }
    }

    &.is-loading {
      cursor: not-allowed;
    }

    &.is-completed {
      @include success-gradient;
      cursor: auto;
    }

    &.is-disabled {
      &.is-loading,
      &.is-completed {
        filter: grayscale(0);
      }
    }
  }

  &--ghost {
    padding: 11px 30px;
    background-color: transparent;
    border: solid 1px $color-ghost-border;
    font-size: 13px;
    font-family: $font-family-heading;
    letter-spacing: 1px;
    text-shadow: none;

    .c-btn__icon {
      color: $color-link;
    }

    &[disabled] {
      .c-btn__content {
        opacity: 0.5;
      }
    }

    &.is-active {
      background-color: rgba($color-grey-light, 0.15);
    }
  }

  &--ghost-pill {
    padding: 9px 15px;
    background-color: transparent;
    border: solid 1px $color-ghost-border;
    font-size: 15px;
    font-family: $font-family-heading;
    letter-spacing: 1px;
    text-shadow: none;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    text-transform: none;
    color: $color-ui-tertiary;
    line-height: 1;
  }

  // DEBUG

  &--debug {
    margin: 1px 5px;
  }

  &--debug-large {
    padding: 15px 40px;
    font-size: 24px;
    background-color: green;
  }
}
