.c-minimap-link {
  background-image: url(../../assets/images/placeholder-minimap.jpg);
  background-position: 100% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $color-panel-background;
  position: relative;
  border-top: solid 1px rgba(#000, 0.32);
  padding: 14px 70px 14px 20px;
  border-radius: $border-radius-default;

  &__name {
    color: $color-ui-primary;
    margin-bottom: 2px;
  }

  &__address {
    color: $color-ui-secondary;
    font-size: 13px;
    font-weight: $font-weight-default;
  }

  &__opening-hours {
    color: $color-ui-tertiary;
    font-size: 13px;
  }

  &__icon {
    width: 20px;
    height: 23px;
    background-image: url(../../assets/images/icon-minimap.svg);
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
}
