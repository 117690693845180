.c-membership-badge {
    position: relative;
    background-color: $color-membership-silver;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 8px;
    line-height: 1.1;
    letter-spacing: 2px;

    strong {
        font-weight: $font-weight-strong;
    }

    &--silver {
        background: $color-membership-silver; /* Old browsers */
        background: -moz-linear-gradient(left, rgba(221,221,221,1) 0%, rgba(201,201,201,1) 30%, rgba(232,232,232,1) 59%, rgba(201,201,201,1) 81%, rgba(221,221,221,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(221,221,221,1) 0%,rgba(201,201,201,1) 30%,rgba(232,232,232,1) 59%,rgba(201,201,201,1) 81%,rgba(221,221,221,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(221,221,221,1) 0%,rgba(201,201,201,1) 30%,rgba(232,232,232,1) 59%,rgba(201,201,201,1) 81%,rgba(221,221,221,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dddddd', endColorstr='#dddddd',GradientType=1 ); /* IE6-9 */
    }

    &--gold {
        background: $color-membership-gold;
        background: -moz-linear-gradient(left, rgba(150,110,46,1) 0%, rgba(178,143,28,1) 32%, rgba(252,222,166,1) 59%, rgba(173,132,38,1) 81%, rgba(150,110,46,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(150,110,46,1) 0%,rgba(178,143,28,1) 32%,rgba(252,222,166,1) 59%,rgba(173,132,38,1) 81%,rgba(150,110,46,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(150,110,46,1) 0%,rgba(178,143,28,1) 32%,rgba(252,222,166,1) 59%,rgba(173,132,38,1) 81%,rgba(150,110,46,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#966e2e', endColorstr='#966e2e',GradientType=1 ); /* IE6-9 */
    }

    &--platinum {
        background: $color-membership-platinum;
        background: -moz-linear-gradient(left, rgba(213,216,221,1) 0%, rgba(173,181,198,1) 25%, rgba(239,244,255,1) 56%, rgba(173,181,198,1) 81%, rgba(213,216,221,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(213,216,221,1) 0%,rgba(173,181,198,1) 25%,rgba(239,244,255,1) 56%,rgba(173,181,198,1) 81%,rgba(213,216,221,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(213,216,221,1) 0%,rgba(173,181,198,1) 25%,rgba(239,244,255,1) 56%,rgba(173,181,198,1) 81%,rgba(213,216,221,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d5d8dd', endColorstr='#d5d8dd',GradientType=1 ); /* IE6-9 */
    }

    &--button {
        font-size: 10px;
        padding: 9px 25px;
        display: inline-flex;
        border-radius: 20px;
        letter-spacing: 1px;
        box-shadow: 0 5px 10px 0 rgba(#000, 0.25);
    }
}