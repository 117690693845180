.s-welcome {
  @include scene;

  display: flex;
  flex-flow: column nowrap;
  padding: 45px 0 20px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("../../assets/images/title-background-mobile.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    animation: welcomeBackgroundFadeIn 1s both $ease-out-sine;

    @media screen and (min-width: 600px) {
      background-image: url("../../assets/images/title-background-desktop.jpg");
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.9) 76.56%
    );
    height: 100%;
    z-index: 2;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    @include desktop-center(400px);
  }

  &__logo {
    max-height: 115px;
    height: 100%;
    width: 100%;
    padding-top: 20px;
  }

  &__carousel {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    transition: all 0.5s $ease-out-expo;
    flex: 1;

    animation: welcomeCarouselFadeIn 1s 0.85s both $ease-out-expo;
  }

  &__dots {
    transition: all 0.5s $ease-out-expo;
  }

  &__buttons {
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 30px;
    margin-inline: auto;
    margin-top: 40px;
    max-width: 400px;

    @media screen and (max-height: 699px) {
      margin-top: 15px;
    }
  }

  &__button {
    .c-btn {
      color: #fff;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;

      @media screen and (max-height: 699px) {
        padding-bottom: 12px;
        padding-top: 12px;
        font-size: 12px;
      }

      &--dark {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    + .s-welcome__button {
      margin-top: 1rem;

      @media screen and (max-height: 699px) {
        margin-top: 0.5rem;
      }
    }
  }

  &__next,
  &__skip {
    position: fixed;
    bottom: 0;
    height: $height-page-dots;
    height: calc(#{$height-page-dots} + env(safe-area-inset-bottom));
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    font-size: 13px;
    color: $color-ui-tertiary;
    cursor: pointer;
  }

  &__skip {
    left: 0;
  }

  &__next {
    right: 0;
  }

  &__language {
    position: absolute;
    top: 12px;
    right: 15px;
  }
}

@keyframes welcomeBackgroundFadeIn {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes welcomeCarouselFadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
