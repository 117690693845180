/**
 * @deprecated
 */

.c-suboptions {
  box-sizing: border-box;
  padding: 0;
  height: 0;
  overflow: hidden;
  padding: 0 30px;
  border-top: solid 1px #000;

  &__wrapper {
    padding: 12px 0;
  }

  &__title {
    padding-top: 5px;
    margin-bottom: 8px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url(../../assets/images/icon-suboption.svg);
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__item {
    position: relative;
    padding: 8px 0;
    font-size: 13px;

    &.is-selected {
      .c-suboptions__icon {
        background-image: url(../../assets/images/icon-option-selected.svg);
      }
    }
  }
}
