.c-shop-marker {
  position: relative;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  background-image: url(../../assets/images/logo-mroom-simple.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
