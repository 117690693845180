.c-barbershop-card {
  position: relative;
  background-color: $color-panel-background;
  display: flex;
  align-items: center;
  min-height: 92px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: $border-radius-default;
  margin: 0 0 3px;
  cursor: pointer;
  width: 100%;
  transition: all 0.25s $ease-out-expo;
  text-align: left;

  .no-touch &:hover {
    background-color: lighten(#1f1f20, 5%);
  }

  &__favorite {
    display: inline-block;
    font-size: 0;
    vertical-align: middle;
    margin-left: 5px;
  }

  &__icon {
    position: absolute;
    content: "";
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 8px;
    height: 13px;
    font-size: 0;
  }

  &__indicator {
    width: 92px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    flex-shrink: 0;
  }

  &__message {
    width: $width-timer-small;
    height: $width-timer-small;
    box-shadow: 0 0 0 3px #3d3d3d inset;
    color: $color-ui-tertiary;
    border-radius: 50%;
    font-size: 10px;
    padding: 5px;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__distance {
    font-size: 10px;
    color: $color-ui-tertiary;
    margin-top: 3px;
  }

  &__main {
    position: relative;
    padding-left: 15px;
    padding-right: 30px;
    flex: 1;

    &::before {
      content: "";
      background-color: #3d3d3d;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -2px;
      width: 1px;
    }
  }

  &__title {
    margin-bottom: 1px;
  }

  &__subtitle {
    color: $color-ui-secondary;
    font-weight: 500;
    font-size: 13px;
  }

  &__barbers {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }

  &__opening-hours {
    color: $color-ui-tertiary;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;

    path,
    circle {
      stroke: $color-ui-tertiary;
    }
  }

  &[disabled],
  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.75;

    .no-touch &:hover {
      background-color: #1f1f20;
    }

    &,
    .c-barbershop-card__subtitle {
      color: $color-ui-tertiary;
    }

    .c-barbershop-card__icon {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}
