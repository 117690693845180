.s-bonus-teaser {
  @include scene;

  &__wrapper {
    @include desktop-center($max-width-list);
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: with-safe-area-bottom(with-navigation(20px));
    min-height: 100%;
  }

  &__header {
    margin-top: 30px;
    text-align: center;
  }

  &__preheadline {
    font-family: $font-family-heading;
    color: $color-ui-tertiary;
    margin-bottom: 8px;
  }

  &__title {
    max-width: 30ch;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    &:only-child {
      margin-bottom: 45px;
    }
  }

  &__content {
    margin-top: 25px;
    padding-bottom: 15px;

    > * {
      margin-bottom: 25px;
      @include desktop-center($max-width-list);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__success {
    background-color: $color-panel-background;
    padding: 25px 25px;
    border-radius: $border-radius-large;
    text-align: left;
  }

  &__form {
    margin-top: 0;
    padding-bottom: 30px;
    background-color: $color-panel-background;
    border-radius: $border-radius-large;
    padding: 25px 25px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  &__form-inner {
    margin-top: 35px;
    text-align: left;
  }

  &__subtitle {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;

    &:empty {
      display: none;
    }

    &:only-child {
      margin-bottom: 0;
    }
  }

  &__form-title {
    text-align: center;
  }

  &__form-icon {
    margin-top: -5px;
    margin-bottom: 25px;

    svg {
      height: 64px;
      width: 64px;
    }
  }

  &__menu-item {
    margin-left: -25px;
    margin-right: -25px;
    margin-top: 25px;

    .c-menu-item {
      border-top: 1px solid #282424;
    }
  }

  &__benefit {
    position: relative;
    background-color: $color-panel-background;
    margin-left: -25px;
    margin-right: -25px;
    text-align: center;
    padding: 25px 25px;

    &-image {
      img {
        max-width: 100%;
      }
    }

    &-title {
      max-width: 28ch;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      margin-top: 10px;
      font-size: 20px;
    }

    &-display {
      font-size: 40px;
      color: $color-brand;
    }

    &-footer {
      margin-top: 25px;
      border-top: solid 1px $color-ui-tertiary;
      padding-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;

      > * {
        &:nth-child(odd) {
          text-align: left;
          margin-right: auto;
        }

        &:nth-child(even) {
          text-align: right;
          margin-left: auto;
        }
      }

      h6 {
        font-size: 14px;
        max-width: 18ch;
      }
    }

    .s-bonus-teaser__form {
      background-color: rgba(#000, 0.5);
      backdrop-filter: blur(10px);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }

    &.is-disabled {
      min-height: 460px;
    }
  }
}
