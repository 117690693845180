.c-help-screen {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;

  h1,
  h2 {
    color: $color-ui-primary;
    margin-bottom: 30px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 1px 5px rgba(#000, 0.5);
  }

  h3,
  h4 {
    margin-top: 20px;
    margin-bottom: 5px;
    text-shadow: 0 1px 5px rgba(#000, 0.5);
  }

  p {
    color: $color-ui-secondary;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 15px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 1px 5px rgba(#000, 0.5);

    @include bp(sm) {
      font-size: 15px;
    }

    @media screen and (max-height: 500px) {
      font-size: 13px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $color-link;
      font-weight: 700;
    }
  }

  &__content {
    padding-bottom: 40px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-flow: column nowrap;

    @media screen and (max-height: 500px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &--less-padding {
      padding-bottom: 15px;
    }
  }

  &__image {
    height: 120px;
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__icons {
    position: relative;
    max-width: 250px;
    margin: 0 auto;
  }

  &__icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 28px;

    h3 {
      font-size: 13px;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 15px;
      color: $color-ui-primary;
      text-transform: uppercase;
      text-align: left;
    }

    img {
      align-self: center;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__links {
    position: relative;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 20px;
    height: 100%;
    max-height: 460px;

    h2 {
      margin-left: 0;
      margin-right: 75px;
      margin-bottom: 10px;
      line-height: 1.1;

      strong {
        font-weight: 700;
        color: $color-brand;
      }
    }

    p {
      margin-left: 0;
      margin-right: 50px;

      strong {
        font-weight: 700;
        color: $color-ui-primary;
      }
    }
  }

  &__link {
    position: relative;
    text-align: left;
    background-color: rgba(#232323, 0.75);
    padding: 10px 25px;
    border-radius: 8px;
    min-height: 130px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;

    &::after {
      content: "";
      width: 13px;
      height: 22px;
      background-image: url(../../assets/images/icon-arrow-right-yellow.png);
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
    }

    &:first-child {
      margin-top: 0;
    }

    h2,
    p {
      text-shadow: none;
    }
  }

  &__divider {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    text-transform: uppercase;
    font-size: 15px;
    color: $color-ui-secondary;
    flex-grow: 1;

    @media screen and (max-height: 500px) {
      font-size: 13px;
    }

    &::before,
    &::after {
      content: "";
      height: 1px;
      background-color: #282424;
      flex: 1;
    }

    &::before {
      margin-right: 10px;
    }

    &::after {
      margin-left: 10px;
    }
  }

  &--with-image {
    .c-help-screen__content {
      justify-content: flex-end;
    }
  }

  &--with-links {
    justify-content: center;
  }
}
