@function with-safe-area-bottom($value) {
  @return calc(env(safe-area-inset-bottom) + #{$value});
}

@function with-navigation($value) {
  @return calc(#{$height-navigation} + #{$value});
}

@function with-tracking-button($value) {
  @return calc(#{$tracking-button-safe-zone} + #{$value});
}
