.s-help {
    @include scene;

    &__back {
        border-bottom: 1px solid rgba(#fff, 0.15);
        padding: 35px 20px 15px 20px;
    }

    &__title {
        padding: 40px 30px 0;
    }
}
