.c-login-dialog {
  border-radius: $border-radius-large;
  background-color: $color-body-highlight;
  text-align: center;
  padding: 30px 0;
  pointer-events: all;

  &__title {
    padding: 10px 45px 0 45px;
    margin-bottom: 20px;
    max-width: 25ch;
    margin-left: auto;
    margin-right: auto;
  }

  &__close {
    padding: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    width: 24px;
    height: 24px;
  }
}
