.c-bonus-counter {
  position: relative;

  &__value {
    font-size: 50px;
    font-family: $font-family-heading;
    font-weight: $font-weight-heading;
    line-height: 1.4;
  }

  &__expiration {
    color: $color-ui-tertiary;
    font-size: 15px;
  }
}
