html {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  background-color: $color-body;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

/**
 * Prevent focus outlines unless the user has pressed the tab key.
 */
html:not(.has-user-tabbing) *:focus,
html:not(.has-user-tabbing) *:focus-within {
  outline: none;
}

body {
  font-family: $font-family-default;
  line-height: $line-height-base;
  background-color: $color-body;
  color: $color-text;
  height: 100%;
  overflow: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include bp(md) {
    background-image: linear-gradient(
      0deg,
      rgb(8, 8, 8) 1%,
      rgb(26, 26, 28) 100%
    );
  }
}

.root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $color-body;
  color: $color-text;

  // @include bp(md) {
  //   max-width: 600px;
  //   position: absolute;
  //   left: 50%;
  //   top: 0;
  //   transform: translateX(-50%);
  // }
}

.app {
  height: 100%;
  overflow: hidden;

  &__core {
    position: relative;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    // Fragments created by our React Transition system
    > div {
      height: 100%;
      overflow: hidden;

      > div {
        height: 100%;
        overflow: hidden;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 2;
  }

  // During development, show a warning that production api is active
  &--experimentalAPI {
    &::before,
    &::after {
      position: fixed;
      content: "";
      pointer-events: none;
      z-index: 99999999999999999;
    }

    &::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //border: solid 5px red;
      border: solid 5px rgb(255, 187, 0);
    }

    &::after {
      //content: "WARNING: PRODUCTION API";
      content: "EXPERIMENTAL API";
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      //background: red;
      background: rgb(255, 187, 0);
      color: white;
      font-weight: 700;
      font-size: 12px;
      display: inline-block;
      padding: 5px 10px 3px;
    }
  }
}

// a little dirty hack to speed our beloved animations from animate.css
// .animated {
//     -webkit-animation-fill-mode: backwards;
//     animation-duration: 0.2s;
// }

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s $ease-out-expo;

  &:focus {
    color: inherit;
    text-decoration: none;
  }

  // &:hover, &:focus {
  // 	text-decoration: none;
  // 	cursor: pointer;
  // 	color: inherit;
  // }
}

// PC Firefox adds bizarre red box shadow by default to text like input fields
input:not([type="submit"]):not([type="file"]) {
  box-shadow: none;
}
