.c-dropdown {
  position: relative;
  max-width: 100%;

  &__placeholder {
    // @include smooth-gradient(180deg, #444, 1);

    background-color: $color-panel-background;
    border-radius: $border-radius-default;
    // border: solid 1px darken(#838383, 5%);
    line-height: 1;
    padding: 6px 20px 5px 7px;
    text-align: center;
    color: $color-ui-tertiary;
    border: none;
    font-size: 15px;

    &::after {
      position: absolute;
      top: 50%;
      right: 7px;
      margin-top: -1px;
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #838383;
    }
  }

  &__select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    color: #000;

    text-indent: 0.01px;
    text-overflow: "";
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
