@mixin bp($point: "sm") {
  $xs: 320px;
  $sm: 768px;
  $md: 992px;
  $lg: 1200px;
  $xl: 1600px;

  @if $point == xs {
    @media (min-width: $xs) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: $sm) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: $md) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: $lg) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: $xl) {
      @content;
    }
  } @else if $point == iPhoneX {
    @media screen and (display-mode: standalone) and (width: 375px) and (height: 768px),
      screen and (display-mode: standalone) and (width: 724px) and (height: 375px),
      screen and (display-mode: fullscreen) and (width: 375px) and (height: 768px),
      screen and (display-mode: fullscreen) and (width: 724px) and (height: 375px) {
      @content;
    }
  } @else if $point == standalone {
    @media (display-mode: standalone) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: $md) {
      @content;
    }
  }
}
