.c-tracking-button {
  position: fixed;
  top: auto;
  bottom: 0px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: with-safe-area-bottom(with-navigation(15px));
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 1;

  button {
    width: 100%;
    position: relative;
    pointer-events: auto;
    background-color: $color-panel-background;
    border-radius: $border-radius-default;
    border: none;
    padding: 12px 40px 12px 23px;
    min-height: 63px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    @include desktop-center($max-width-list + 50px);
  }

  &__background {
    content: "";
    @include full-cover;
    @include smooth-gradient(0deg, #000, 1);
    z-index: -1;
  }

  &__icon {
    width: 8px;
    height: 13px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
    font-size: 0;
  }

  &__timer {
    margin-right: 20px;
    font-size: 0;
  }

  &__message {
    font-size: 13px;
    line-height: 17px;
  }
}
