.c-service-info {
  position: relative;

  &__toggle {
    margin-bottom: 5px;
    margin-left: -$gutter;
    margin-right: -$gutter;
  }

  &__description {
    color: $color-ui-secondary;
  }
}
