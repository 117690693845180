// Collapsible Question and Answer component
.c-qanda {

    &__question {

    }

    &__answer {
        p:first-child {
            margin-top: 0;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }

    .is-active {
    }

}
