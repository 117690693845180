$height-map: 225px;
$height-map-small: 180px;
$height-map-large: 325px;
$height-map-extra-large: 450px;

.c-deprecated-barbershops-map {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.5s $ease-out-expo;

  &__wrapper {
    // position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $height-map;
    transform-origin: top center;
    transition: height 0.75s $ease-out-expo, transform 0.75s $ease-out-expo;
    z-index: 1;
    background-color: #3d3d3d;
    background-image: url(../../assets/images/map-loading-placeholder.jpg);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform: translate3d(-100%, 0, 0);

    @media screen and (max-height: 550px) {
      height: $height-map-small;
    }

    @media screen and (min-height: 768px) {
      height: $height-map-large;
    }

    @media screen and (min-height: 900px) {
      height: $height-map-extra-large;
    }

    &::after {
      @include smooth-gradient(0deg, #000, 1);

      content: "";
      position: absolute;
      bottom: -1px; // avoid sub-pixel issues
      left: 0;
      width: 100%;
      height: 110px;
      pointer-events: none;
      z-index: 1;

      @media screen and (max-height: 550px) {
        height: 80px;
      }

      @include bp(standalone) {
        height: 80px;
      }
    }
  }

  &__map {
    display: none;

    .is-in-viewport & {
      display: block;
    }
  }

  &__locator {
    position: fixed;
    top: 20px;
    left: 20px;
    pointer-events: auto;
    z-index: 2;
    font-size: 0;

    .is-centered & {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -10px;
    }
  }

  &__toggle {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
    pointer-events: auto;
    transition: all 0.5s $ease-out-expo;
  }

  &__message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 20px;
    font-size: 13px;
    color: $color-ui-secondary;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 1px 1px rgba(#000, 0.1);
    text-align: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    &.is-html {
      font-size: 11px;
      color: $color-ui-tertiary;
      text-transform: none;
      letter-spacing: 0;

      strong {
        font-size: 13px;
        color: $color-ui-secondary;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: block;
      }
    }
  }

  &.is-expanded {
    .c-deprecated-barbershops-map__wrapper {
      height: 100%;
      overflow: hidden;
    }

    .c-deprecated-barbershops-map__toggle {
      transform: translateY(-65px);
    }
  }

  &.is-in-viewport {
    .c-deprecated-barbershops-map__wrapper {
      transform: translate3d(0, 0, 0);
    }
  }
}
