@mixin smooth-gradient($deg, $color, $opacity) {
    background: linear-gradient($deg,
    rgba($color, $opacity * 1) 0%,
    rgba($color, $opacity * 0.738) 19%,
    rgba($color, $opacity * 0.541) 34%,
    rgba($color, $opacity * 0.382) 47%,
    rgba($color, $opacity * 0.278) 56.5%,
    rgba($color, $opacity * 0.194) 65%,
    rgba($color, $opacity * 0.126) 73%,
    rgba($color, $opacity * 0.075) 80.2%,
    rgba($color, $opacity * 0.042) 86.1%,
    rgba($color, $opacity * 0.021) 91%,
    rgba($color, $opacity * 0.008) 95.2%,
    rgba($color, $opacity * 0.002) 98.2%,
    rgba($color, $opacity * 0) 100%
);
}