@mixin transition-active() {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.transition {

    &--default {
        &-enter {
            z-index: 2;
            opacity: 0;
            transform: translate3d(0, 0, 0) scale(0.9);

            &-active {
                @include transition-active;

                opacity: 1;
                transform: translate3d(0, 0, 0) scale(1);
                transition: all 0.75s $ease-out-expo;
            }
        }

        &-exit {
            z-index: 1;

            &-active {
                @include transition-active;

                opacity: 0;
                transform: translate3d(0, 0, 0) scale(0.9);
                transition: all 0.75s $ease-out-expo;
            }
        }
    }

    &--instant {
        &-enter {
            z-index: 2;
        }

        &-exit {
            z-index: 1;
        }
    }

    &--slide-left {
        &-enter {
            z-index: 2;
            transform: translate3d(-100%, 0, 0);

            &-active {
                @include transition-active;

                transform: translate3d(0, 0, 0);
                transition: all 0.75s $ease-out-expo;
            }
        }

        &-exit {
            z-index: 1;

            &-active {
                @include transition-active;

                transform: translate3d(100%, 0, 0);
                transition: all 0.75s $ease-out-expo;
            }
        }
    }

    &--slide-right {
        &-enter {
            z-index: 2;
            transform: translate3d(100%, 0, 0);

            &-active {
                @include transition-active;

                transform: translate3d(0, 0, 0);
                transition: all 0.75s $ease-out-expo;
            }
        }

        &-exit {
            z-index: 1;

            &-active {
                @include transition-active;

                transform: translate3d(-100%, 0, 0);
                transition: all 0.75s $ease-out-expo;
            }
        }
    }

    &--switch-open {
        &-enter {
            z-index: 3;
            transform: translate3d(110%, 0, 0) scale(1.1);

            &-active {
                @include transition-active;

                transform: translate3d(0, 0, 0) scale(1);
                transition: all 0.75s $ease-out-expo;
            }
        }

        &-exit {
            z-index: 1;

            &-active {
                @include transition-active;

                transform: translate3d(-50%, 0, 0) scale(0.9);
                opacity: 0.5;
                transition: all 0.75s $ease-out-expo;
            }
        }
    }

    &--switch-close {
        &-enter {
            z-index: 1;
            transform: translate3d(-50%, 0, 0) scale(0.9);
            opacity: 0.5;

            &-active {
                @include transition-active;

                transform: translate3d(0, 0, 0) scale(1);
                opacity: 1;
                transition: all 0.75s $ease-out-expo;
            }
        }

        &-exit {
            z-index: 3;

            &-active {
                @include transition-active;

                transform: translate3d(110%, 0, 0) scale(1.1);
                transition: all 0.75s $ease-out-expo;
            }
        }
    }
}
