.c-order-review {
  $gutter: 30px;

  border-radius: 20px;
  overflow: hidden;
  background-color: $color-black-almost;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;

  @media screen and (min-height: 768px) {
    min-height: 500px;
  }

  &__header {
    position: relative;
    min-height: 35px;
  }

  &__image {
    @include bgimg;

    position: relative;
    height: 155px;
    cursor: pointer;
    padding: 0;

    &::after {
      @include smooth-gradient(0deg, $color-panel-background, 1);

      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      pointer-events: none;
    }
  }

  &__timer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__back {
    position: absolute;
    top: 20px;
    left: 23px;
  }

  &__content {
    $accept-order-button-height: 55px;
    background-color: $color-body-highlight;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: with-safe-area-bottom(
      calc(#{$gutter + 5px} * 2 + #{$accept-order-button-height})
    );
  }

  &__subtitle {
    color: $color-ui-primary;

    &--recommendations {
      margin-bottom: 14px;
    }
  }

  &__title {
    color: $color-ui-primary;
    margin-bottom: 3px;
  }

  &__address {
    font-size: 15px;
    color: $color-ui-secondary;
  }

  &__info {
    padding: 12px $gutter 22px;

    @media screen and (min-height: 768px) {
      padding: 12px $gutter 45px;
    }
  }

  &__services {
    position: relative;
    background-color: rgba(#fff, 0.05);
    border-radius: $border-radius-default;
    margin-left: $gutter;
    margin-right: $gutter;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 15px;
    padding-right: 15px;

    > * {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__button-notice {
    font-size: 12px;
    padding: 15px 0;
    color: $color-ui-secondary;
  }

  &__notice {
    margin-top: 15px;
  }

  &__footer {
    // padding-left: $gutter;
    // padding-right: $gutter;
    // padding-bottom: 65px;

    @include center-children;

    position: fixed;
    left: 0;
    bottom: calc(env(safe-area-inset-bottom) + 65px);
    padding-top: $gutter + 5px;
    padding-left: $gutter;
    padding-right: $gutter;
    padding-bottom: with-safe-area-bottom($gutter + 5px);
    width: 100%;
    z-index: 4;

    &::before {
      content: "";
      @include full-cover;
      @include smooth-gradient(0deg, #000, 1);
    }

    .c-btn {
      @include desktop-center($max-width-list);
    }
  }

  &__recommendations {
    padding-left: $gutter;
    padding-right: $gutter;
    margin-top: 25px;
  }

  &__footer-arrow {
    @include smooth-gradient(0deg, $color-panel-background, 1);

    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - #{$gutter});
    max-width: $max-width-modal;
    height: 100px;
    z-index: 9999;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    pointer-events: none;
    padding-bottom: 20px;
  }
}
