.c-map-marker {
  width: 32px;
  height: 32px;
  background-image: url(../../assets/images/map-marker.png);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-top: -16px;
  margin-left: -16px;
  transform: scale(0);
  animation: mapMarkerIn 0.5s $ease-out-expo forwards;
}

@keyframes mapMarkerIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
