.c-service-display {
  position: relative;

  &__content {
    position: relative;
    padding-right: 55px;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__description {
    color: $color-ui-tertiary;
    font-size: 13px;
    font-weight: $font-weight-default;
  }

  &__price {
    font-size: 13px;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 1px;
  }

  &__extra-services {
    margin-top: 5px;

    &:empty {
      margin-top: 0;
    }
  }
}
