.s-deprecated-barbershop {
  $image-height: 185px;
  $image-height-l: 225px;
  $image-height-xl: 325px;

  @include scene;

  text-align: left;
  z-index: 2;

  &__wrapper {
    position: relative;
    height: 100%;
    overflow-x: hidden;
  }

  &__content {
    position: relative;
    z-index: 2;
    background-color: #000;
    padding-bottom: $height-queue-button;
  }

  &__header {
    position: relative;
    padding-top: $image-height;
    z-index: 3;

    @media screen and (min-height: 768px) {
      padding-top: $image-height-l;
    }

    @media screen and (min-height: 900px) {
      padding-top: $image-height-xl;
    }
  }

  &__header-notice {
    position: absolute;
    left: 50%;
    bottom: 70px;
    text-align: center;
    transform: translate(-50%, 5px);
    width: 180px;
    transition: all 0.5s $ease-out-expo;
    opacity: 0;

    p {
      position: relative;
      margin-top: 3px;
      font-size: 11px;
      line-height: 1.2;

      &::after {
        position: absolute;
        bottom: -15px;
        left: 50%;
        margin-left: -5px;
        content: "";
        width: 10px;
        height: 10px;
        background-image: url(../../assets/images/icon-arrow-down-small-white.svg);
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        transition: all 0.5s $ease-out-expo;
        transform: translateY(10px);
      }
    }
  }

  &__timer {
    position: absolute;
    left: 50%;
    bottom: -($width-timer-regular / 2);
    margin-left: -($width-timer-regular / 2); // There was a weird transition bug happening on ios when using translate(-50%)
    transform: scale(1);
    transition: transform 0.5s $ease-out-expo;
  }

  &__message {
    position: absolute;
    left: 50%;
    bottom: -($width-timer-regular / 2);
    margin-left: -($width-timer-regular / 2); // There was a weird transition bug happening on ios when using translate(-50%)
    transform: scale(1);
    transition: transform 0.5s $ease-out-expo;
    width: $width-timer-regular;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    flex-shrink: 0;
  }

  &__sign {
    width: $width-timer-regular;
    height: $width-timer-regular;
    box-shadow: 0 0 0 3px #3d3d3d inset;
    color: $color-ui-tertiary;
    background-color: rgba(#000, 0.9);
    border-radius: 50%;
    font-size: 14px;
    padding: 5px;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__fixed-header {
    position: fixed;
    background-color: #000;
    top: 0;
    left: 0;
    height: 75px;
    width: 100%;
    z-index: 3;
    border-bottom: solid 1px transparent;
    box-shadow: 0 15px 45px 0 rgba(#000, 0);
    transform: translateY(-100%);
    transition: all 0.5s $ease-out-expo;

    &.is-scrolled {
      transform: translateY(0);
      border-bottom: solid 1px #282424;
      box-shadow: 0 15px 45px 0 rgba(#000, 0.75);
    }
  }

  &__fixed-title {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 45px;
    white-space: nowrap;
  }

  &__fixed-timer {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0%);
    transition: all 0.5s $ease-out-expo;

    .is-scrolled & {
      transform: translate(-50%, 50%);
    }
  }

  &__image {
    @include bgimg;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $image-height;
    z-index: 1;
    opacity: 0.75;
    transform-origin: center top;
    transition: opacity 1s $ease-out-expo;
    // animation: animateBarbershopImage 1s 0.5s $ease-out-expo both;

    @media screen and (min-height: 768px) {
      height: $image-height-l;
    }

    @media screen and (min-height: 900px) {
      height: $image-height-xl;
    }
  }

  &__back {
    position: absolute;
    top: 37px;
    left: 20px;
    z-index: 4;
  }

  &__title {
    margin-bottom: 18px;
    padding-right: 45px;
  }

  &__notice {
    padding: 0 30px 28px;
    max-width: $max-width-list;
    margin: 0 auto;
  }

  &__details {
    padding: 60px 30px 28px;
    max-width: $max-width-list;
    margin: 0 auto;

    p {
      font-size: 13px;
      color: $color-ui-secondary;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__services {
    position: relative;
    max-width: $max-width-list;
    margin: 0 auto;

    &--inactive {
      opacity: 0.3;
      pointer-events: none;

      &::after {
        @include full-cover;

        content: "";
        pointer-events: auto;
      }
    }
  }

  &__queue {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $height-queue-button;
    transform: translate3d(0, 100%, 0);
    transition: all 0.5s $ease-out-expo;
    z-index: 4;
  }

  &__spinner {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(#000, 0);
    transition: all 0.5s $ease-out-expo;
    z-index: 5;

    &.is-active {
      background-color: rgba(#000, 1);
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  &__map {
    @include full-cover;

    visibility: hidden;
    background-image: url(../../assets/images/map-loading-placeholder-large.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #bababa;
    transform: translateY(100%);
    transition: all 0.5s $ease-out-expo;

    &.is-active {
      visibility: visible;
      transform: translateX(0);
    }
  }

  &__map-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__favorite {
    position: absolute;
    top: 60px;
    right: 25px;
    z-index: 4;
    text-align: right;
  }

  &--selected {
    .s-deprecated-barbershop__queue {
      transform: translate3d(0, 0, 0);
    }
  }

  &--loading {
    .s-deprecated-barbershop__timer {
      opacity: 0.5;
    }

    .s-deprecated-barbershop__spinner {
      display: block;
    }

    .s-deprecated-barbershop__map {
      &::after {
        opacity: 0.5;
      }
    }
  }

  &--show-header-notice {
    .s-deprecated-barbershop__image {
      opacity: 0.35;
    }

    .s-deprecated-barbershop__header-notice {
      opacity: 1;
      transform: translate(-50%, 0);
      transition: all 1s $ease-out-expo;

      p {
        &::after {
          transform: translateY(0);
          transition: all 1s $ease-out-expo;
        }
      }
    }
  }
}

@keyframes animateBarbershopImage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
