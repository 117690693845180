.c-slogan {
  @include hide-text;

  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(../../assets/images/slogan.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
