.c-service-small {
    position: relative;
    border-top: solid 1px rgba(#000, 0.32);
    padding: 12px 20px 10px;

    &__title {
        margin-bottom: 2px;
    }

    &__description {
        color: $color-ui-secondary;
        font-size: 13px;
        font-weight: $font-weight-default;
    }

    &__unit {
        font-weight: $font-weight-strong;
        font-size: 13px;
        padding-top: 2px;
    }

    &__extra {
        font-size: 10px;
        margin-top: 3px;
    }

    &--text-only {
        border-top: none;
        padding: 12px 0 10px;
    }
}