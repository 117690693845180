.reset-all {
	width: 20px;
	height: 20px;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 99999999999;
}

.go-to-debug {
	width: 20px;
	height: 20px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999999999;
}