.s-check-in {
  @include scene;

  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  overflow: hidden;
  height: 100%;
  z-index: 1;
  transition: all 1s $ease-out-expo;

  // Avoid sub-pixel overflow issues when transitioning
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    bottom: -2px;
    z-index: -1;
    background-image: url(../../assets/images/title-background.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__wrapper {
    background-color: $color-body-highlight;
    border-radius: $border-radius-large;
    border: solid 1px #373636;
    padding: 30px 25px;
    text-align: center;

    p {
      font-size: 15px;
      color: $color-ui-secondary;
      line-height: 1.2;
      font-weight: 500;
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__receipt {
    margin-top: 20px;
    color: $color-link;
    text-transform: uppercase;
  }
}
