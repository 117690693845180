/**
 * @deprecated
 */

.c-service {
  padding: 20px 0;
  border-top: 1px solid rgba(#fff, 0.3);

  &__header {
    padding: 0 30px;
  }

  &__title {
    margin-bottom: 5px;
    color: $color-ui-primary;
    transition: all 0.25s $ease-out-expo;
  }

  &__description {
    font-size: 13px;
    line-height: 15px;
    color: $color-ui-secondary;
    transition: all 0.25s $ease-out-expo;
  }

  &__options {
    margin: 0;
    padding: 8px 0 0 0;
    list-style-type: none;
  }

  &.has-selected {
    .c-service__title,
    .c-service__description {
      opacity: 0.4;
    }
  }
}
