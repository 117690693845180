$height-map: 225px;
$height-map-small: 175px;
$height-map-large: 325px;
$height-map-extra-large: 450px;

.c-map-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.5s $ease-out-expo;

  &__wrapper {
    // position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $height-map;
    transform-origin: top center;
    transition: height 0.75s $ease-out-expo, transform 0.75s $ease-out-expo;
    z-index: 1;
    background-color: #3d3d3d;
    background-image: url(../../assets/images/map-loading-placeholder.jpg);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform: translate3d(-100%, 0, 0);

    @media screen and (max-height: 550px) {
      height: $height-map-small;
    }

    @media screen and (min-height: 768px) {
      height: $height-map-large;
    }

    @media screen and (min-height: 900px) {
      height: $height-map-extra-large;
    }

    &::after {
      @include smooth-gradient(0deg, #000, 1);

      content: "";
      position: absolute;
      bottom: -1px; // avoid sub-pixel issues
      left: 0;
      width: 100%;
      height: 110px;
      pointer-events: none;
      z-index: 1;

      @media screen and (max-height: 550px) {
        height: 80px;
      }

      @include bp(standalone) {
        height: 80px;
      }
    }
  }

  &__map {
    display: none;

    .show-map & {
      display: block;
    }
  }

  &__locator {
    position: fixed;
    top: 20px;
    left: 20px;
    pointer-events: auto;
    z-index: 2;
  }

  &__toggle {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
    pointer-events: auto;
    transition: all 0.5s $ease-out-expo;
  }

  &--expanded {
    .c-map-container__wrapper {
      height: 100%;
      overflow: hidden;
    }

    .c-map-container__toggle {
      transform: translateY(-65px);
    }
  }

  &.map-unrendered {
    .c-map-container__wrapper {
      transform: translate3d(0, calc(-100% - 2px), 0);
    }
  }

  .show-map & {
    .c-map-container__wrapper {
      transform: translate3d(0, 0, 0);
    }
  }
}
