.c-search-input {
  position: relative;

  &__input {
    border: none;
    border-radius: 5px;
    background-color: $color-panel-background;
    width: 100%;
    min-height: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 2.5rem;
    padding-right: 2rem;
    font-size: 16px;
    text-overflow: ellipsis;

    &::placeholder {
      color: #8e8e93;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__search-icon,
  &__clear-icon {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background: transparent;
      border: none;
    }
  }

  &__search-icon {
    left: 0;
    padding: 10px 15px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  &__clear-icon {
    right: 0;
    padding: 10px 12px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
