.c-big-number {
    font-size: 80px;
    color: $color-brand;
    text-shadow: 0 0 20px rgba($color-brand, 0.75);
    display: inline-block;
    width: 160px;
    height: 160px;
    line-height: 154px;
    text-align: center;
    margin: 0 auto;
    border: 4px solid currentColor;
    border-radius: 50%;
}
