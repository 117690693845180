.c-confirm-dialog {
  border-radius: $border-radius-large;
  background-color: $color-body-highlight;
  border: solid 1px #373636;
  text-align: center;
  padding: 30px 0;

  &__title {
    padding: 10px 45px 0 45px;
    margin-bottom: 20px;
  }

  &__description {
    padding: 0 45px;
    margin-bottom: 10px;

    strong {
      font-weight: $font-weight-strong;
    }
  }

  &__buttons {
    padding-top: 20px;
  }
}
