/**
 * Place all basic typography here. You can define more specific
 * font styles in the components.
 *
 * 1) Do not define any colors here. All these fonts will need to
 *    work in both light and dark areas.
 * 2) All margins and paddings should be defined in either the
 *    wysiwyg-html mixin or specific components.
 */

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-heading;
  font-weight: $font-weight-heading;
  line-height: $line-height-heading;
  margin: 0;
  padding: 0;
}

h1,
.h1 {
  font-size: 26px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 14px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

// Other text elements
p {
  margin: 0;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }
  &.accent {
    font-style: italic;
  }
}

strong {
  font-weight: $font-weight-default;
}

blockquote {
  padding: 0;
  border-left: 0.5em solid $color-brand;
  margin: 0;
}

em {
  position: relative;
}

ul,
ol {
  margin: 0;
  padding: 0 0 0 2em;
}

hr {
  height: 1px;
  border: none;
  background: rgba(125, 125, 125, 0.5);
}

i {
  position: relative;
}

// Links
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.1s ease;

  &:hover,
  &:focus {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }
}
