.c-show-and-hide {
  transition: all 0.5s $ease-out-expo;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  &.is-visible {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: all 1s $ease-out-expo;
  }
}
