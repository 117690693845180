.c-recovery-form {
    $country-width: 65px;

    margin: 0 auto;
    max-width: 100%;

    &__field {
        padding-top: 20px;
        position: relative;
        margin-bottom: 25px;
    }

    &__instructions {
        font-size: 11px;
        line-height: 1.2;
        margin-top: 6px;
    }

    &__submit {
        text-align: center;
        margin-top: 35px;
    }

    &__links {
        text-align: center;
        margin-top: 30px;
        font-size: 13px;
        color: $color-text-secondary;

        a {
            display: block;
            position: relative;
            padding: 8px 0;

            strong {
                font-weight: 700;

                color: $color-ui-primary;
            }
        }
    }

    &__description {
        font-size: 13px;
        color: $color-ui-secondary;
        margin-bottom: 15px;
    }

    &__country-code {
        position: absolute;
        top: 23px;
        left: 5px;
        width: 65px;
    }

    &__static {
        color: $color-ui-tertiary;
        font-size: 12px;
        margin-bottom: 20px;
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        font-size: 12px;
        color: $color-input;
        width: 100%;
        margin-bottom: 3px;
    }

    input:not([type="submit"]) {
        font-size: 16px;
        font-weight: 500;
        border: solid 1px transparent;;
        background: rgba(255,255,255,0.12);
        border-radius: $border-radius-large;
        color: $color-input;
        width: 100%;
        padding: 7px 10px;

        &:focus {
            color: $color-input-focus;

            & + label {
                color: $color-input-focus;
            }
        }

        &.has-error {
            border: solid 1px $color-danger;
        }
    }

    input.c-recovery-form__phone {
        padding-left: $country-width + 12px;
    }

    &__error-message {
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
        color: $color-danger;
        font-size: 12px;
        margin-top: 5px;
    }
}
