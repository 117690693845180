.c-message {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px #333;

    &__header {
        position: relative;
        background: transparent;
        border: none;
        text-align: left;
        width: 100%;
        padding: 10px 30px 10px 0;

        &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-unread-message;
            position: absolute;
            top: 17px;
            right: 0;
        }
    }

    &__date {
        font-size: 12px;
        opacity: 0.5;
    }

    &__content {
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        transition: all 0.5s $ease-out-expo;

        p {
            font-size: 14px;
        }

        a {
            color: $color-brand;
        }
    }

    &.is-expanded {
        .c-message__content {
            height: auto;
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }

    &.is-read {
        .c-message__header {
            &::after {
                display: none;
            }
        }
    }
}
