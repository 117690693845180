.c-benefit-button {
  position: relative;
  height: 190px;
  width: 100%;
  background-color: $color-panel-background;
  border-radius: 10px;
  border: solid 2px $color-panel-background;
  padding: 10px;

  &[disabled] {
    border-style: dashed;
    border-color: #3d3d3d;
    background-color: transparent;
    color: #3d3d3d;
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
  }

  &__footer {
    font-size: 13px;
    line-height: 15px;
    font-family: $font-family-heading;
  }

  &__value {
    font-family: $font-family-heading;
    font-size: 35px;
    line-height: 1;
    margin-top: 22px;
    margin-bottom: 32px;
  }

  &__link {
    svg {
      font-size: 0;
      margin-left: 10px;
      display: inline-block;
      transform: translateY(2px);
    }
  }
}
