.c-horizontal-button-group {
  display: flex;
  flex-flow: row nowrap;

  button {
    flex-grow: 1;
    flex-basis: 0;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    &:not(:last-child) {
      border-right: none;
    }
  }
}
