// Add to any element to make it smooth scrollable
.h-scrollable {
  overflow-y: scroll;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
}

.h-text-center {
  text-align: center;
}

.h-text-left {
  text-align: left;
}

.h-text-right {
  text-align: right;
}

.h-text-small {
  font-size: 0.7em;
}

.h-std {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  p {
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 25px;

    @include bp(sm) {
      font-size: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.h-danger {
  color: $color-danger;
}

.h-share-icon {
  display: inline-block;
  width: 12px;
  height: 1em;
  background-image: url(../../assets/images/icon-share.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.h-text-button {
  background: none;
  border: none;
}

.h-button-wrapper {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.h-nowrap {
  white-space: nowrap;
}
