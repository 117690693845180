.s-list {
  $height-locate-prompt: 50px;
  $height-map: 225px;
  $height-map-small: 175px;
  $height-map-large: 325px;
  $height-map-extra-large: 450px;

  @include scene;

  &__map {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $height-map;
    transform-origin: top center;
    transition: height 1s $ease-out-expo;
    z-index: 1;
    background-color: #3d3d3d;
    background-image: url(../../assets/images/map-loading-placeholder.jpg);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    @media screen and (max-height: 550px) {
      height: $height-map-small;
    }

    @media screen and (min-height: 768px) {
      height: $height-map-large;
    }

    @media screen and (min-height: 900px) {
      height: $height-map-extra-large;
    }
  }

  &__map-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__wrapper {
    position: relative;
    top: $height-locate-prompt + 20px;
    width: 100%;
    background-color: #000;
    z-index: 9;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: $height-navigation + 20px;
    padding-bottom: calc(
      #{$height-navigation} + env(safe-area-inset-bottom) + 20px
    );
    text-align: left;
    transition: all 1s $ease-out-expo;
    pointer-events: none;

    &::before {
      @include full-cover;

      content: "";
      background-color: #000;
      opacity: 0;
      transition: all 0.5s $ease-out-expo;
      pointer-events: none;
      z-index: 2;
    }

    &--is-member {
      top: $height-locate-prompt + 15px;
    }

    // There's a weird CSS bug when scrolling the map with a mouse, making top
    // 1px less than height of map seems to fix it
    &--with-map {
      top: $height-map - 1px;

      @media screen and (max-height: 550px) {
        top: $height-map-small - 1px;
      }

      @media screen and (min-height: 768px) {
        top: $height-map-large - 1px;
      }

      @media screen and (min-height: 900px) {
        top: $height-map-extra-large - 1px;
      }

      &::after {
        @include smooth-gradient(0deg, #000, 1);

        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 110px;
        pointer-events: none;
        transform: translateY(-109px);
        z-index: 1;

        @media screen and (max-height: 550px) {
          height: 80px;
          transform: translateY(-79px);
        }

        @include bp(standalone) {
          height: 80px;
          transform: translateY(-79px);
        }
      }

      &.s-list__wrapper--is-member {
        padding-top: 5px;
      }
    }
  }

  &__prompt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $height-locate-prompt;
    z-index: 10;
    transition: all 0.35s $ease-out-expo;

    &::after {
      @include smooth-gradient(180deg, #000, 1);

      content: "";
      position: absolute;
      top: $height-locate-prompt;
      left: 0;
      width: 100%;
      height: 20px;
      pointer-events: none;
      z-index: -1;
      transition: all 0.35s $ease-out-expo;
    }

    &--failure {
      position: absolute;
    }
  }

  &__label {
    font-size: 12px;
    color: $color-ui-tertiary;
    margin-top: 8px;
    margin-bottom: 2px;

    &--favorite {
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        transform: translateY(1px);
        margin-right: 5px;
        background-image: url(../../assets/images/icon-favorite-active.svg);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }

  &__item {
    pointer-events: auto;
    max-width: $max-width-list;
    margin: 0 auto;

    &:first-child {
      .s-list__label {
        margin-top: 0;
      }
    }
  }

  &__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  &__divider {
    margin-bottom: 20px;
  }

  &__membership {
    text-align: center;
    pointer-events: auto;
    position: fixed;
    bottom: calc(#{$height-navigation} + env(safe-area-inset-bottom) + 10px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 10;
  }

  &__refresh {
    position: fixed;
    bottom: $height-navigation + 5px;
    bottom: calc(#{$height-navigation} + env(safe-area-inset-bottom) + 5px);
    right: 12px;
    z-index: 10;
    animation: animateRefreshButton 1s 2s $ease-out-expo both;
  }

  &--loading {
    .s-list__wrapper {
      &:before {
        opacity: 0.5;
      }
    }
  }

  &--with-map {
    .s-list__prompt {
      transform: translateY(-100%);

      &::after {
        opacity: 0;
      }
    }
  }
}

@keyframes animateRefreshButton {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
