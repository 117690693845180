.s-notice {
  //@include scene;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  overflow: hidden;
  z-index: 1;

  // Avoid sub-pixel overflow issues when transitioning
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    bottom: -2px;
    z-index: -1;
    background-image: url(../../assets/images/title-background.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgba(#fff, 0.15);
    padding: 35px 20px 15px 20px;
  }

  &__content {
    text-align: center;
    overflow: auto;

    h1,
    h2 {
      margin-bottom: 15px;
    }

    p {
      color: $color-ui-secondary;
      line-height: 1.3;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    strong {
      color: $color-ui-primary;
      font-weight: 700;
    }
  }

  &__button {
    margin-top: 30px;
  }

  .bypass {
    width: 15px;
    height: 15px;
    position: fixed;
    //border: solid 1px red;

    &--a {
      top: 0;
      right: 0;
      left: auto;
      bottom: auto;
    }

    &--b {
      top: auto;
      bottom: 0;
      left: 0;
      right: auto;
    }
  }
}
