.c-language-link {
  color: $color-ui-secondary;
  text-transform: uppercase;
  font-family: $font-family-heading;
  letter-spacing: 1px;
  font-size: 11px;
  line-height: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
