.s-receipt {
    @include scene;

    text-align: left;

    &__back {
        border-bottom: 1px solid rgba(#fff, 0.15);
        padding: 35px 20px 15px 20px;
    }

    &__content {
        max-width: $max-width-list;
        margin: 0 auto;
    }

    &__details {
        padding: 20px 20px;

        h3 {
            margin-top: 15px;
            margin-bottom: 2px;
        }

        p {
            font-size: 13px;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__order {
        border-top: 1px solid rgba(#fff, 0.15);
        padding: 20px;
    }

    &__subtitle {
        color: $color-ui-tertiary;
        margin-bottom: 8px;
    }
}
