.s-tracking {
  @include scene;

  padding-left: $gutter;
  padding-right: $gutter;
  padding-top: 10px;
  padding-bottom: with-safe-area-bottom(calc(#{$height-navigation} + 10px));
  display: flex;
  flex-flow: column nowrap;
  overflow-x: hidden;
  z-index: 1;

  &:not(.h-scrollable) {
    overflow-y: hidden;
  }

  &__checkin-background-wrapper {
    @include full-cover;
    position: fixed;
    overflow: hidden;
    z-index: -1;
  }

  &__checkin-background {
    @include full-cover;
    background-image: url(../../assets/images/title-background.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__wrapper {
    position: relative;
    transition: all 0.5s $ease-out-expo;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100%;
    flex-shrink: 0;
  }

  &__panel {
    @include desktop-center($max-width-modal);
    position: relative;
    border-radius: $border-radius-large;
    background-color: rgba(#000, 0.7);
    z-index: 2;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;

    &:first-child {
      margin-top: 30px; // compensate for missing recipe toggle
    }

    &:last-child {
      margin-bottom: 47px; // compensate for missing cancel order button
    }
  }

  &__footer {
    margin-top: auto;
    padding-top: 20px;
  }

  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 10px 0;
    background: none;
    border: none;
    width: 100%;

    @media screen and (min-height: 600px) {
      padding: 45px 0 25px 0;
    }

    @media screen and (min-height: 750px) {
      padding: 60px 0 50px 0;
    }

    @include bp(desktop) {
      margin-top: auto;
    }
  }

  &__cancel-order {
    position: relative;
    z-index: 1;
    min-height: 55px;
  }

  &__location {
    text-align: left;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__receipt-toggle {
    text-align: right;
    transition: all 0.5s $ease-out-expo;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &__message {
    text-align: center;
    padding: 0 30px;
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(#000, 0);
    transition: all 0.5s $ease-out-expo;
    z-index: 2;

    &.is-active {
      background-color: rgba(#000, 1);
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  &__spinner {
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 99;
    opacity: 0;
    transition: all 0.5s $ease-out-expo;
  }

  &__check-in {
    @include full-cover;

    z-index: 1;
    transform: translateX(100%);
    transition: all 0.5s $ease-out-expo;
    z-index: 3;

    &.is-active {
      transform: translateX(0);
    }
  }

  &__check-in-button {
    border-bottom-left-radius: $border-radius-large;
    border-bottom-right-radius: $border-radius-large;
    overflow: hidden;
    margin-bottom: 10px;
  }

  &__confirm {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s $ease-out-expo;

    &.is-active {
      opacity: 1;
      visibility: visible;
      transition: all 0.5s $ease-out-expo;
    }
  }

  &__map {
    @include full-cover;

    background-image: url(../../assets/images/map-loading-placeholder-large.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #bababa;
    z-index: 2;
  }

  &__map-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__receipt {
    @include full-cover;

    color: #fff;
    background-color: #000;
    transform: translateY(100%);
    transition: all 0.5s $ease-out-expo;
    z-index: 4;

    &.is-active {
      transform: translateY(0);
    }
  }

  &--loading {
    .s-tracking__spinner {
      opacity: 1;
    }
  }
}
