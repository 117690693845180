.c-fixed-header {
  position: fixed;
  background-color: #000;
  top: -1px; // weird sub-pixel issue
  left: 0;
  width: 100%;
  z-index: 3;
  border-bottom: solid 1px transparent;
  box-shadow: 0 15px 45px 0 rgba(#000, 0);
  transform: translateY(-100%);
  transition: all 0.5s $ease-out-expo;

  &.is-visible {
    transform: translateY(0);
    border-bottom: solid 1px #282424;
    box-shadow: 0 15px 45px 0 rgba(#000, 0.75);
  }
}
