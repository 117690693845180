.s-error {
    @include scene;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: center;
    padding: 20px 15px;
}
