.c-locator {
  position: relative;
  background-color: $color-body;
  background-image: url("../../assets/images/icon-locator.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: $border-radius-large;
  width: 42px;
  height: 42px;
  border: 1px solid rgba(#dcdcdc, 0.45);

  &--loading {
    background-image: none;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
      border-radius: 50%;
      width: 28px;
      height: 28px;
      font-size: 10px;
      text-indent: -9999em;
      border-top: 2px solid rgba($color-brand, 0.3);
      border-right: 2px solid rgba($color-brand, 0.3);
      border-bottom: 2px solid rgba($color-brand, 0.3);
      border-left: 2px solid $color-brand;
      animation: locatorSpinner 0.75s infinite linear;
    }
  }

  &--error {
    background-image: url("../../assets/images/icon-cross.svg");
    background-size: 16px 16px;
    background-color: $color-danger;
    border: none;
  }
}

@keyframes locatorSpinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
