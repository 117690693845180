.c-help-btn {
    position: absolute;
    top: 35px;
    right: 20px;
    color: #fff;
    opacity: 0.4;

    a {
        font-size: 14px;
        font-weight: $font-weight-strong;
        display: block;
        width: 22px;
        height: 22px;
        line-height: 23px;
        padding-left: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: solid 2px currentColor;
    }
}
