.c-email-form {
  position: relative;

  &__label {
    display: block;
    font-size: 14px;
    color: $color-input;
    width: 100%;
    margin-bottom: 3px;
    font-family: $font-family-heading;
  }

  // @todo Refactor to use the TextInput field instead
  &__input {
    display: block;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-bottom: solid 2px $color-ghost-border;
    background: transparent;
    color: $color-input;
    width: 100%;
    margin-top: 3px;
    padding: 11px 0;
    border-radius: $border-radius-small;

    &:focus {
      color: $color-input-focus;
      border-color: $color-brand;

      & + label {
        color: $color-input-focus;
      }
    }

    &.has-error {
      color: $color-danger;
      border-bottom-color: $color-danger;
    }

    &.is-valid {
      color: $color-input-focus;
      border-bottom-color: $color-success;
    }

    &:-webkit-autofill {
      box-shadow: $color-ghost-border 0 0 0 30px inset !important;
    }
  }

  &__error-message {
    color: $color-danger;
    font-size: 12px;
    margin-top: 5px;
  }

  &__marketing-permission {
    margin-top: 30px;
  }

  &__button {
    margin-top: 30px;
  }
}
