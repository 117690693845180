.s-bonus-system {
  @include scene;

  &__wrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: with-safe-area-bottom(with-navigation(20px));
    background-image: url("../../assets/images/bonus-background.jpg");
    background-position: 50% 0%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: 100%;
  }

  &__counter {
    margin-top: 80px;

    @include desktop-center($max-width-list);
  }

  &__benefits {
    margin-top: 50px;

    @include desktop-center($max-width-list);
  }
}
