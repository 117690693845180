.s-debug {
    @include scene;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column nowrap;
    text-align: center;
    padding: 20px 15px;

    &__devmode {
        p {
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;
            font-size: 13px;
            line-height: 1.2;
            margin-top: 10px;
        }
    }

    &__api-toggle {
        h6 {
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        p {
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;
            font-size: 13px;
            line-height: 1.2;
            margin-top: 10px;
        }
    }

    &__sw-toggle {
        h6 {
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }
}
