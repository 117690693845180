.c-welcome-carousel {
  position: relative;

  &,
  .flickity-viewport {
    height: 100%;
  }

  &__slide {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    opacity: 0;
    transition: opacity 0.5s $ease-out-expo;

    h2 {
      margin-bottom: 16px;
    }

    @media screen and (max-height: 500px) {
      padding-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 20px;
    }

    &.is-selected {
      opacity: 1;
    }
  }
}
