.c-list-label {
  font-size: 12px;
  color: $color-ui-tertiary;
  margin-top: 20px;
  margin-bottom: 2px;

  &__icon {
    width: 12px;
    height: 10px;
    display: inline-block;
    transform: translateY(1px);
    margin-right: 5px;
    font-size: 0;
  }
}
