.s-barbershop {
  $image-height: 230px;
  $image-height-l: 250px;
  $image-height-xl: 325px;

  @include scene;

  text-align: left;
  z-index: 2;

  &__wrapper {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;

    &:not(.h-scrollable) {
      overflow-y: hidden;
    }
  }

  &__content {
    $accept-order-button-height: 55px;

    @include desktop-center($max-width-list);
    position: relative;
    z-index: 2;
    background-color: #000;
    padding-bottom: with-safe-area-bottom(
      calc(#{$gutter + 5px} * 2 + #{$accept-order-button-height})
    );
  }

  &__header {
    position: relative;
    z-index: 3;
  }

  &__header-notice {
    position: absolute;
    left: 50%;
    bottom: 70px;
    transform: translate(-50%, 0);
  }

  &__timer {
    position: absolute;
    left: 50%;
    bottom: -($width-timer-regular / 2);
    margin-left: -($width-timer-regular / 2); // There was a weird transition bug happening on ios when using translate(-50%)
    transform: scale(1);
    transition: transform 0.5s $ease-out-expo, filter 0.25s $ease-out-expo;
    background-color: #000;
    border-radius: 100%;
  }

  &__sign {
    width: $width-timer-regular;
    height: $width-timer-regular;
    box-shadow: 0 0 0 3px #3d3d3d inset;
    color: $color-ui-tertiary;
    background-color: rgba(#000, 0.9);
    border-radius: 50%;
    font-size: 14px;
    padding: 5px;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__fixed-header-content {
    &__title {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 0 45px;
      white-space: nowrap;
    }

    &__timer {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
      transition: all 0.5s $ease-out-expo;

      .is-visible & {
        transform: translate(-50%, 50%);
      }
    }
  }

  &__fixed-title {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 45px;
    white-space: nowrap;
  }

  &__fixed-timer {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    transition: all 0.5s $ease-out-expo;

    &.is-visible {
      transform: translate(-50%, 50%);
    }
  }

  &__image {
    @include bgimg;
    @include desktop-center($max-width-list + 100px);

    position: relative;
    height: $image-height;
    z-index: 1;
    opacity: 0.75;
    transform-origin: center top;
    transition: opacity 1s $ease-out-expo;
    filter: brightness(0.8);
    background-color: $color-panel-background;

    @media screen and (min-height: 768px) {
      height: $image-height-l;
    }

    @media screen and (min-height: 900px) {
      height: $image-height-xl;
    }

    &::after {
      @include full-cover;
      content: "";
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.0001) 79.8%,
        #000000 100%
      );
      pointer-events: none;
    }
  }

  &__back {
    position: absolute;
    top: 37px;
    left: 20px;
    z-index: 4;
  }

  &__details {
    padding: 70px $gutter 35px;
    max-width: $max-width-list;
    margin: 0 auto;

    &__title {
      margin-bottom: 8px;
      padding-right: 45px;
    }

    &__address,
    &__opening-hours {
      font-size: 13px;
      margin-bottom: 0;
    }

    &__address {
      color: $color-ui-secondary;
    }

    &__opening-hours {
      color: $color-ui-tertiary;
    }

    &__chair-count {
      font-family: $font-family-heading;
      color: $color-grey;
      text-align: center;
      margin-bottom: 28px;

      .c-barbershop-card__barbers {
        justify-content: center;

        gap: 4px;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-right: 0;
      }
      path,
      circle {
        stroke: $color-grey;
      }
    }
  }

  &__notice {
    padding: 0 $gutter 35px;
    max-width: $max-width-list;
    margin: 0 auto;
  }

  &__details + &__notice {
    margin-top: -15px;
  }

  &__services {
    position: relative;
    max-width: $max-width-list;
    margin: 0 auto;

    &:not(.is-editable) {
      opacity: 0.3;
      pointer-events: none;

      &::after {
        @include full-cover;

        content: "";
        pointer-events: auto;
      }
    }
  }

  &__service-group-selector {
    border-top: 1px solid #3d3d3d;
    padding-top: 23px;
    padding-bottom: 13px;
  }

  &__service-group {
    border-top: 1px solid #3d3d3d;
    padding: 23px 0;
  }

  &__service-group-selector + &__service-group {
    border-top: none;
    padding-top: 10px;
  }

  &__accept-order {
    @include center-children;

    position: fixed;
    left: 0;
    bottom: calc(env(safe-area-inset-bottom) + 65px);
    padding-top: $gutter + 5px;
    padding-left: $gutter;
    padding-right: $gutter;
    padding-bottom: with-safe-area-bottom($gutter + 5px);
    width: 100%;
    z-index: 4;

    &::before {
      content: "";
      @include full-cover;
      @include smooth-gradient(0deg, #000, 1);
    }

    .c-btn {
      @include desktop-center($max-width-list);
    }
  }

  &__loader {
    border: solid 1px red;
    height: 500px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(#000, 0);
    transition: all 0.5s $ease-out-expo;
    z-index: 5;

    &.is-active {
      background-color: rgba(#000, 1);
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  &__map {
    @include full-cover;

    background-image: url(../../assets/images/map-loading-placeholder-large.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #bababa;
    z-index: $z-index-modal + 1;
  }

  &__map-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__favorite {
    position: absolute;
    top: 70px;
    right: 25px;
    margin-top: 4px;
    z-index: 4;
    text-align: right;
    font-size: 0;
  }

  &--selected {
    .s-barbershop__accept-order {
      transform: translate3d(0, 0, 0);
    }
  }
}
