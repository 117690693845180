.c-radiobutton {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    border: 1px solid rgba(#fff, 0.5);
    border-radius: 50%;

    .is-selected &,
    &.is-selected {
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 6px;
            left: 6px;
            right: 6px;
            bottom: 6px;
            background: rgba(#fff, 0.5);
            border-radius: 50%;
        }
    }
}
