.c-password-form {

    &__errors {

    }

    &__success {
        font-size: 15px;
        p {
            margin: 10px 0 0;
        }
    }

    &__input {
        margin-bottom: 25px;

        label {
            font-size: 12px;
            display: block;
            margin-bottom: 2px;

            @include bp(sm) {
                font-size: 13px;
                margin-bottom: 3px;
            }
        }

        input {
            width: 100%;
            background-color: rgba(#8E8E93, 0.2);
            color: #fff;
            border: none;
            border-radius: $border-radius-large;
            padding: 8px 15px;
            font-size: 16px;
        }

        &__error {
            color: $color-danger;
            font-size: 12px;
        }
    }

    &__errors {
        color: $color-danger;
        font-size: 12px;
        margin: 0 0 10px;
    }

    .has-error input {
        border: 1px solid $color-danger;
    }

    &__submit {

    }

}
