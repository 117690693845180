/**
 * Define the global variables used across the project here.
 *
 * 1) Always create variables with a meaningful context.
 * 2) Avoid defining component specific variables on the global level.
 * 3) See other files in the base folder for more global styles.
 */

// Project colors.
$color-black-almost: #1f1f20;
$color-yellow-gold: #e5b42d;
$color-yellow-gold-dark: #9f6c1f;
$color-yellow-brown: #6c4a16;
$color-brown: #543300;
$color-grey: #838383;
$color-grey-lighter: #ccc;
$color-grey-light: #dcdcdc;
$color-grey-dark: #444;
$color-grey-extra-dark: #201f1f;
$color-white-almost: #f6f6f6;
$color-red: #c22327;

// Bind colors to meaningful variables.
$color-brand: $color-yellow-gold;
$color-brand-dark: $color-yellow-gold-dark;
$color-brand-shadow: $color-brown;
$color-text: #fff;
$color-text-secondary: $color-grey-light;
$color-heading: #fff;
$color-body: #000;
$color-link: $color-yellow-gold;
$color-link-hover: darken($color-yellow-gold, 15%);
$color-overlay: #000;
$color-input: $color-grey;
$color-input-focus: $color-grey-light;
$color-danger: $color-red;
$color-warning: $color-yellow-gold;
$color-success: #00b894;
$color-ui-primary: #fff;
$color-ui-secondary: $color-grey-light;
$color-ui-tertiary: $color-grey;
$color-ui-priority: $color-yellow-gold;
$color-button: $color-yellow-gold;
$color-button-secondary: $color-yellow-gold-dark;
$color-body-highlight: $color-black-almost;
$color-membership-silver: #dddddd;
$color-membership-gold: #966e2e;
$color-membership-platinum: #d5d8dd;
$color-unread-message: $color-brand;
$color-unread-message-text: #000;
$color-panel-background: $color-grey-extra-dark;
$color-ghost-border: rgba($color-grey-light, 0.25);

// @deprecated
$color-wait-none: lighten($color-yellow-gold, 5%);
$color-wait-short: $color-yellow-gold;
$color-wait-medium: $color-yellow-gold-dark;
$color-wait-long: $color-yellow-brown;

// Timer gradient colors
$gradient-queue-0: conic-gradient(
  from 180deg at 50% 50%,
  #dae74b -47.38deg,
  #86c109 170.11deg,
  #dae74b 312.62deg,
  #86c109 530.11deg
);

$gradient-queue-5: conic-gradient(
  from 180deg at 50% 50%,
  #e39e17 0deg,
  #f5d669 85.73deg,
  #e39e17 360deg
);

$gradient-queue-15: conic-gradient(
  from 180deg at 50% 50%,
  #f2884d 0deg,
  #efb865 177.61deg,
  #f2884d 360deg
);

$gradient-queue-30: conic-gradient(
  from 180deg at 50% 50%,
  #d1540e 0deg,
  #ebaa7a 179.49deg,
  #d1540e 360deg
);

$gradient-queue-60: $gradient-queue-30;

// Original, red (deemed too danger-color):
// $gradient-queue-60: conic-gradient(
//   from 180deg at 50% 50%,
//   #ad1414 0deg,
//   #dd6044 183.24deg,
//   #ad1414 360deg
// );

// Timer pulse colors
$pulse-queue-0: #86c109;
$pulse-queue-5: #e39e17;
$pulse-queue-15: #f2884d;
$pulse-queue-30: #d1540e;
$pulse-queue-60: $pulse-queue-30;

// Original, red
// $pulse-queue-60: #ad1414;

// Font families
$font-family-default: "Roboto", Helvetica, Arial, sans-serif;
$font-family-heading: "AvenirNextLTPro-Demi", "Roboto", Helvetica, Arial,
  sans-serif;

// Font weights
$font-weight-default: 400;
$font-weight-strong: 700;
$font-weight-heading: 600;

// Line heights
//$line-height-base:          1.628571429;
$line-height-base: 1.5;
$line-height-heading: 1.2;

// Border radiuses
$border-radius-default: 5px;
$border-radius-small: 2px;
$border-radius-large: 10px;
$border-radius-extra-large: 15px;

// Box shadows
$box-shadow-default: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
$box-shadow-large: 0 3px 40px 0 rgba(0, 0, 0, 0.2);

// Border colors
$border-color-default: #ccc;
$border-color-light: #f4f4f4;

// Animation easing methods
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-out-elastic: cubic-bezier(0.64, 0.57, 0.67, 1.53);

// Transitions
$transition-default: all 0.5s $ease-out-expo;
$transition-fast: all 0.25s $ease-out-expo;
$transition-slow: all 1s $ease-out-sine;
$transition-slide: all 1s $ease-in-out-quart;

// Opacity
$opacity-overlay: 0.5;

// Element heights
$height-navigation: 65px;
$height-navigation-with-safe-area: calc(
  #{$height-navigation} + env(safe-area-inset-bottom)
);
$height-navigation-item: 65px;
$height-queue-button: 70px; // @deprecated
$height-page-dots: 70px;
$height-display-number: 192px;
$height-display-number-small: 130px;

// Timer sizes
$width-timer-tiny: 36px;
$width-timer-small: 52px;
$width-timer-regular: 90px;
$width-timer-large: 138px;

// Various width limits
$max-width-readability: 560px;
$max-width-list: 600px;
$max-width-modal: 400px;

// Temp variables:

// // Colors
// $gold:          #c79137;
// $green:         #0ac572;
// $purple:        #5c00e7;
// $black:         #000;
// $almost-black:  #201f1f; // or #22182c ?
// $dark-grey:     #1f1f20;
// $light-grey:    #ccc;
// $almost-white:  #F5F7F7;
// $white:         #fff;

// // Link colors to meaningful variables
// $brand-color:      $gold;
// $body-bg:          $black;
// $text-color:       $white;
// $link-color:       $brand-color;
// $link-hover-color: darken($link-color, 15%);

// UI settings
$ui-border-radius: 5px;

// Page settings
$page-padding: 24px;

// Easing methods
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

// Z index
$z-index-modal: 99;

// Gutter
$gutter: 25px;

// Tracking button
$tracking-button-safe-zone: 75px;
