.s-title {
  @include scene;

  padding: 30px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  overflow: hidden;
  z-index: 1;

  // Avoid sub-pixel overflow issues when transitioning
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    left: -2px;
    bottom: -2px;
    z-index: -1;
    background-image: url(../../assets/images/title-background.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__logo {
    width: 165px;
    animation: titleLogoIn 1s $ease-out-expo both;
  }

  &__slogan {
    margin-top: 30px;
    margin-bottom: 45px;
    animation: titleSloganIn 1s 0.15s $ease-out-expo both;
  }
}

@keyframes titleLogoIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes titleSloganIn {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
