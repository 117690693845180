.s-settings {
    @include scene;

    &__back {
        border-bottom: 1px solid rgba(#fff, 0.15);
        padding: 35px 20px 15px 20px;
    }

    &__header {
        padding: 25px 30px 20px;
        max-width: $max-width-readability;
        margin: 0 auto;
    }

    &__title {
        margin-bottom: 3px;
    }

    &__subtitle {
        font-size: 15px;
        margin-top: 20px;
        line-height: 1.2;
        width: 80%;
    }

    &__subtitle-link {
        font-size: 13px;
        margin-top: 10px;
        color: $color-link;
    }

    &__notice {
        margin-top: 15px;

        p {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 15px;
            line-height: 1.3;
        }
    }

    &__inline-notice {
        margin-top: 20px;

        p {
            line-height: 1.2;
        }
    }

    &__content {
        border-top: solid 1px #282424;
        padding: 30px 30px 20px;
        max-width: $max-width-readability;
        margin: 0 auto;
        transition: opacity 0.5s $ease-out-expo;

        &--no-xpadding {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__content-title {
        margin-bottom: 10px;
    }

    &__item {
        margin-bottom: 25px;
    }

    &__item-label {
        font-weight: 400;
        color: $color-ui-secondary;
    }

    &__item-value {
        font-size: 24px;
        font-weight: 700;
        color: $color-ui-primary;
    }

    &__description {
        font-size: 13px;
        color: $color-ui-secondary;
        margin-bottom: 25px;
    }

    &__input {
        margin-bottom: 25px;

        label {
            font-size: 12px;
            display: block;
            margin-bottom: 2px;

            @include bp(sm) {
                font-size: 13px;
                margin-bottom: 3px;
            }
        }

        input {
            width: 100%;
            background-color: rgba(#8E8E93, 0.2);
            color: #fff;
            border: none;
            border-radius: $border-radius-large;
            padding: 8px 15px;
            font-size: 16px;
        }
    }

    &__errors {
        margin: 0 0 10px;
    }

    &__order {
        border-top: solid 1px #282424;
        padding: 30px 30px 20px;
    }

    &__spinner {
        display: none;
    }

    &__options {
        margin: 0;
        padding: 8px 0 0 0;
        list-style-type: none;
    }

    &--loading {
        .s-settings__content {
            opacity: 0.5;
        }

        .s-settings__spinner {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
