.c-page-dots {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  width: 100%;
  // height: $height-page-dots;
  // height: calc(#{$height-page-dots} + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;

  &__dot {
    background-color: transparent;
    margin: 0 15px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    box-shadow: 0 0 0 0 $color-brand-dark, 0 0 0 2px #3d3d3d inset;
    transition: box-shadow 0.5s $ease-out-expo;

    &.is-active {
      background-color: $color-brand-dark;
      box-shadow: 0 0 0 2px $color-brand-dark, 0 0 0 0 $color-brand-dark inset;
    }
  }

  &--tight {
    .c-page-dots__dot {
      margin: 0 5px;
    }
  }
}
