.c-receipt {
    position: relative;
    padding: 0 0 10px;

    &__orderid {
        color: $color-ui-tertiary;
        margin: 0 0 5px;
    }

    &__pob {
        margin: 0 0 20px;
    }

    &__time {
        margin: 0 0 20px;
    }

    h3 {
        margin-bottom: 3px;
    }

    &__items {
        line-height: 20px;
        display: block;
        margin: 7px 0 10px;
        padding: 0;
    }

    &__item {
        margin: 0;
        padding: 0;

        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &__amount {
            font-size: 11px;
        }

        &__title {
            flex-grow: 1;
            line-height: inherit;
            padding: 0 0.5em;
        }

        &__price {
            font-size: 13px;
            font-weight: $font-weight-strong;
            text-align: right;
        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        margin-top: 20px;
        padding-top: 10px;
        border-top: dashed 1px #333;

        h3,
        h4 {
            line-height: inherit;
        }

        p {
            text-align: right;
        }

        &__vat {
            display: block;
            font-size: 12px;
            font-weight: $font-weight-default;
        }
    }
}
