.c-logo {
  @include hide-text;

  height: 100%;
  width: 100%;
  background-image: url(../../assets/images/logo-mroom.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
