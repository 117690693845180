.c-extra-service-info {
  position: relative;

  &__toggle {
    margin-bottom: 10px;
  }

  &__description {
    color: $color-ui-secondary;
  }
}
