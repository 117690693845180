.c-extra-service-toggle {
  $info-icon-size: 14px;
  $button-price-offset: 60px;

  position: relative;

  &__button {
    position: relative;
    padding: 12px $button-price-offset 12px 35px;
    display: block;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    user-select: none;
  }

  &__title {
    font-size: 15px;
    line-height: 20px;
    display: block;
  }

  &__price {
    position: absolute;
    top: 11px;
    right: 0;
    font-size: 13px;
    line-height: 15px;
    color: $color-ui-secondary;
  }

  &__icon {
    position: absolute;
    top: 13px;
    left: 0;
  }

  &__info {
    position: absolute;
    top: -2px;
    right: -10px;
    padding: 10px;

    svg {
      width: $info-icon-size;
      height: $info-icon-size;
    }
  }

  &.has-info {
    .c-extra-service-toggle__button {
      padding-right: $button-price-offset + $info-icon-size;
    }

    .c-extra-service-toggle__price {
      right: $info-icon-size + 8px;
    }
  }

  &.is-size-large {
    .c-extra-service-toggle__title {
      font-size: 17px;
    }

    .c-extra-service-toggle__icon {
      top: 12px;
    }

    .c-extra-service-toggle__price {
      font-size: 15px;
      top: 16px;
    }
  }
}
