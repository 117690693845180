.c-login-form {
  $country-width: 65px;

  width: 280px;
  margin: 0 auto;
  max-width: 100%;

  &__field {
    padding-top: 18px;
    position: relative;
    margin-bottom: 20px;
    text-align: left;

    &--no-label {
      padding-top: 5px;
    }
  }

  &__submit {
    text-align: center;
    margin-top: 25px;
  }

  &__links {
    text-align: center;
    margin-top: 18px;
    font-size: 13px;
    color: $color-text-secondary;

    a {
      display: block;
      position: relative;
      padding: 8px 0;

      strong {
        font-weight: 700;

        color: $color-ui-primary;
      }
    }
  }

  &__info {
    text-align: center;
  }

  &__country-code {
    position: absolute;
    top: 28px;
    left: 0px;
    width: $country-width;
  }

  .c-checkbox {
    align-items: center;
    &__input {
      height: 25px;
      width: 40px;
      border-radius: 30px;
      background: #3d3d3d;
      appearance: none;
      padding: 4px;
      transition: transform 0.2s ease-in-out;

      &::before {
        content: "";
        display: block;
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background: #fff;

        transition: transform 0.2s ease-in-out;
      }

      &:checked {
        background: linear-gradient(90deg, #e1ad1c 22.51%, #d39333 85.56%);

        &::before {
          transform: translateX(15px);
        }
      }
    }
  }

  &__text-input-label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    font-size: 14px;
    color: $color-input;
    width: 100%;
    margin-bottom: 3px;
    font-family: $font-family-heading;
  }

  // @todo Refactor to use the <TextInput /> component
  input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]) {
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-bottom: solid 2px $color-ghost-border;
    background: transparent;
    color: $color-input;
    width: 100%;
    margin-top: 3px;
    padding: 11px 0;
    border-radius: $border-radius-small;

    &:focus {
      color: $color-input-focus;
      border-color: $color-brand;

      & + label {
        color: $color-input-focus;
      }
    }

    &.has-error {
      border: solid 1px $color-danger;
    }

    &:-webkit-autofill {
      box-shadow: $color-ghost-border 0 0 0 30px inset !important;
    }
  }

  input.c-login-form__phone {
    padding-left: $country-width + 7px !important;
  }

  &__error-message {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    color: $color-danger;
    font-size: 12px;
    margin-top: 5px;
  }
}
