.s-login {
  @include scene;

  height: 100%;
  z-index: 1;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;

  &__wrapper {
    padding-top: 3px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0;
    position: relative;
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
  }

  &__panel {
    @include desktop-center(400px);

    display: none;
    position: relative;
    border-radius: $border-radius-large;
    //background-color: rgba(#000, 0.7);
    //background: linear-gradient(to bottom, rgba(#000, 0.4), rgba(#000, 0.7));
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.9) 76.56%
    );
    width: 100%;
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
    z-index: 2;
  }

  &__top {
    width: 100%;
    flex-shrink: 1;
    flex-flow: column nowrap;
    justify-content: flex-end;
    transition: transform 0.75s $ease-out-expo;
    height: 145px;

    @media screen and (max-height: 600px) {
      transform: translateY(35px);
    }
  }

  &__middle {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    height: 0;
    overflow: hidden;
  }

  &__logo {
    //height: 70%;
    height: 100%;
    width: 100%;
    max-height: 165px + 10px;
    padding-bottom: 10px;

    @media screen and (max-height: 500px) {
      height: 100%;
    }
  }

  &__slogan {
    height: 30%;
    width: 100%;
    max-height: 69px + 10px;
    padding-top: 10px;

    @media screen and (max-height: 460px) {
      display: block;
    }
  }

  &__form {
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translateY(25px);
    // transition: all 0.75s $ease-out-expo;
    transition: none;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0;
  }

  &__bar {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: $color-ui-tertiary;
    display: none;
    margin-top: auto;

    @media screen and (max-height: 500px) {
      display: none;
    }
  }

  &__notification {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    transition: transform 0.5s $ease-out-expo;
  }

  &__language {
    display: none;
    flex-flow: row nowrap;
    justify-content: flex-end;

    @include desktop-center(400px);
  }

  /**
     * Display the login form
     */

  &--form {
    .s-login__spinner {
      display: none;
    }

    .s-login__wrapper {
      background-image: url("../../assets/images/title-background-mobile.jpg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;

      @media screen and (min-width: 600px) {
        background-image: url("../../assets/images/title-background-desktop.jpg");
      }
    }

    .s-login__panel {
      display: block;
    }

    .s-login__top {
      transform: translateY(0);
      animation: loginLogoFakeAnimation 1s $ease-out-expo forwards;
    }

    .s-login__middle {
      height: auto;
      overflow: visible;
    }

    .s-login__logo {
      max-height: 145px;
      padding-bottom: 20px;
    }

    .s-login__slogan {
      max-height: 69px + 7px;
      padding-top: 7px;

      @media screen and (max-height: 500px) {
        display: none;
      }
    }

    .s-login__form {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    .s-login__bar {
      display: flex;
      gap: 0.5rem;

      @media screen and (max-height: 500px) {
        display: none;
      }

      button {
        appearance: none;
        background: transparent;
        border: 0;
      }
    }

    .s-login__language {
      display: flex;
    }
  }

  /**
  * Transitioning to next scene
  */

  &--transitioning {
    &.s-login--form {
      .s-login__form {
        opacity: 0;
      }
    }
  }

  /**
  * Show notification
  */

  &--notification {
    .s-login__notification {
      transform: translateY(0);
    }
  }
}

@keyframes loginLogoIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes loginSloganIn {
  from {
    opacity: 0;
    transform: translate(-50%, -10px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
}

@keyframes loginLogoFakeAnimation {
  from {
    transform: scale(0.75);
  }
  to {
    transform: scale(1);
  }
}

// @keyframes loginBackgroundAnimation {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
